import { gql } from "@apollo/client";

export const GET_SETTINGS = gql`
    query getSettings {
        settings {
            user {
                id
                firstName
                lastName
                username
                email
            }

            profile {
                bio
                influencerType
                imageUrl
            }
        
            metrics {
                id
                qualifier
                quantifier
                selected
            }
        
            services {
                id
                description
                name
                price
                quantifier
                fullDescription
            }
        
            media {
                id
                url
                selected
            }

            platforms {
                name
                userLink
                connected
            }
        }

        constants {
            MAX_SELECTED_MEDIA
            MAX_SELECTED_METRICS
        }
}
`;

export const GET_HOMEPAGE = gql`
query getHomepage($username: String!) {
    homepage(username: $username) {
        user {
            firstName
            lastName
        }

        profile {
            bio
            influencerType
            imageUrl
        }
    
        metrics {
            id
            qualifier
            quantifier
        }
    
        services {
            id
            description
            name
            price
            quantifier
            fullDescription
        }
    
        media {
            id
            url
        }

        platforms {
            name
            userLink
        }

        
    }
}
`;

export const UPDATE_SERVICE = gql`
    mutation changeService($serviceId: ID!, $service: ServiceInput!) {
        updateService(serviceId: $serviceId,  service: $service){
            id
        }
    }
`;

export const DELETE_SERVICE = gql`
    mutation deleteService($serviceId: ID!){
        removeService(id: $serviceId)
    }
`

export const UPDATE_SETTINGS = gql`
    mutation updateSettings($settings: GeneralSettingsInput!){
        updateGeneralSettings(generalSettings: $settings)
    }
`;

export const UPDATE_SELECTED_ITEMS = gql`
    mutation updateSelectedItems($metrics: [MetricInput]!,  $media: [MediaInput]!){
        updateSelectedElements(metrics: $metrics, media: $media)
    }
`;


export const ADD_SERVICE = gql`
    mutation AddService( $service: ServiceInput!) {
        addService(service: $service)
    }
`

export const SIGNUP = gql`
mutation SignupMutation($signupInput: SignupInput!) {
    signup(signupInput: $signupInput) {
      token
    }
  }
`

export const LOGIN = gql`
  mutation login($username: String!, $password: String!) {
    login(username: $username, password: $password) {
      token
      loggedIn
      username
    }
  }
`;

export const LOGIN_CHECK = gql`
  query isLoggedIn {
      isLoggedIn {
          loggedIn
          username
          imageUrl

          user {
              firstName
              lastName
          }
      }
      
  }
`

export const LOGOUT = gql`
  mutation logout {
      logout {
          loggedIn
      }
  }
`

export const USERNAME_EXISTS = gql`
  query existingUserName($username: String!) {
      usernameExists(username: $username)
  }
`

export const RESET_PASSWORD = gql`
  mutation resetPassword($oldPassword: String!, $newPassword: String!){
      setPassword(oldPassword: $oldPassword, newPassword: $newPassword)
  }
`