import { useMutation } from "@apollo/client";
import React from "react";
import { RESET_PASSWORD } from "../../queries";
import { Button } from "../Buttons";
import { Input } from "../Inputs";
import Notification from "../Notification";
import { useObjectState } from "../StateHooks";
import { FormRender, nonEmptyError } from "./FormRender";

const template = (fields) => [
    {
        sectionTitle: "Password Reset",
        sectionDescription: "Update your password",
        inputs: [
            {
                component: Input,
                name: "oldPassword",
                props: {
                    className: "sm:col-span-6",
                    header: "Current Password",
                    type: "password"
                    // maxChar: MAX_FIRST_NAME_COUNT,
                    // charCountAppear: fields.firstName.length >= MAX_FIRST_NAME_COUNT - 10
                },
                errors: [
                    nonEmptyError
                    // nonEmptyError,
                    // lengthError(MAX_FIRST_NAME_COUNT)
                ]
            },
            {
                component: Input,
                name: "newPassword",
                props: {
                    className: "sm:col-span-6",
                    header: "New Password",
                    type: "password"
                    // maxChar: MAX_FIRST_NAME_COUNT,
                    // charCountAppear: fields.firstName.length >= MAX_FIRST_NAME_COUNT - 10
                },
                errors: [
                    nonEmptyError
                    // lengthError(MAX_FIRST_NAME_COUNT)
                ]
            },
            {
                component: Input,
                name: "confirmPassword",
                props: {
                    className: "sm:col-span-6",
                    header: "Confirm New Password",
                    type: "password"
                    // maxChar: MAX_FIRST_NAME_COUNT,
                    // charCountAppear: fields.firstName.length >= MAX_FIRST_NAME_COUNT - 10
                },
                errors: [
                    nonEmptyError,
                    {func: str=>str === fields.newPassword, message: "The new passwords must match."}
                    // lengthError(MAX_FIRST_NAME_COUNT)
                ]
            },
        ]
    }
];



export const PrivacyTab = () => {

    const [fields, setValue, handleChange, setFieldValue] = useObjectState({
        oldPassword: "",
        newPassword: "",
        confirmPassword: ""
    });
    const [showNotification, setShowNotification] = React.useState(false);
    const [notificationType, setNotificationType] = React.useState("ERROR");
    const [notificationMessage, setNotificationMessage] = React.useState("")

    const getValue = attr => fields[attr];

    const [resetPassword] = useMutation(RESET_PASSWORD, {
        variables: {
            oldPassword: fields.oldPassword,
            newPassword: fields.newPassword
        },
        onError: e => {
            setNotificationMessage(e.message);
            setNotificationType("ERROR");
            setShowNotification(true);
        },
        onCompleted: () => {
            setNotificationMessage("Password Updated");
            setNotificationType("SUCCESS");
            setShowNotification(true);
        }
    })


    const buttonTemplate = [
        {
            component: Button,
            type: "SUBMIT",
            props: {
                type: "PRIMARY",
                children: "Reset Password",
                onClick: resetPassword
            }
        }  
    ];


    
    return (
    <>
    <div className="flex-1 xl:overflow-y-auto">
        <div className="max-w-3xl mx-auto py-10 px-4 sm:px-6 lg:py-12 lg:px-8">
            <h1 className="text-3xl font-extrabold text-blue-gray-900">Security</h1>
            <FormRender {...{template: template(fields), getValue, setValue, handleChange, buttonTemplate}}/>
        </div>
    </div>
    <Notification show={showNotification} setShow={setShowNotification} alertType={notificationType} message={notificationMessage} />
    </>
    )

}