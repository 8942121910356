import { Homepage } from "./Homepage";
import { Routes, Route, Link, useNavigate } from "react-router-dom";
import { Settings } from "./Settings";
import { ApolloClient, ApolloProvider, createHttpLink, InMemoryCache, HttpLink, from, useQuery } from "@apollo/client";
import { Login } from "./Login";
import { AUTH_TOKEN } from "./constants";
import {setContext} from "@apollo/client/link/context";
import { RequireAuth } from "./RequireAuth";
import FauxApp from "./PhoApp";
import React from "react";

import { onError } from "@apollo/client/link/error";
import { LOGIN_CHECK } from "./queries";

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem(AUTH_TOKEN);
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}`: ''
    }
  };
});
 
const httpLink = createHttpLink({
  uri: "https://mediapacquet.herokuapp.com/graphql",
  // uri: process.env.STAGE ? "http://localhost:8080/graphql" : "https://mediapacquet.herokuapp.com/graphql",
  credentials: "include" 
});

const errorLink = navigate => {
  
  return onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      for (let err of graphQLErrors) {
        switch (err.extensions.code) {
          case "FORBIDDEN":
            navigate("/login", {replace: true});
            break;

          default:
            break;
        }
      }
    }
    

    if (networkError) console.log(`[Network error]: ${networkError}`);
  });
}


// export const client = new ApolloClient({
//   cache: new InMemoryCache(),
//   link: from([errorLink, httpLink]),
//   credentials: "inlcude"
// })

export const client = (navigate) => {

  return new ApolloClient({
    cache: new InMemoryCache(),
    link: from([errorLink(navigate), httpLink]),
    credentials: "include"
  })
}

export const UserContext = React.createContext();




// const client = () => new ApolloClient({
//   uri: "http://localhost:8080/graphql",
//   cache: new InMemoryCache(),
//   credentials: 'include'
// });

function App() {
  const navigate = useNavigate();
  const authToken = localStorage.getItem(AUTH_TOKEN);

  

  const handleLogout = () => {
    localStorage.removeItem(AUTH_TOKEN);
    navigate("/login", {replace: true})
  }
  
   // const {loading, error} = useQuery(LOGIN_CHECK, {
    //   onCompleted: data => {
    //     const {loggedIn} = data.isLoggedIn;
    //     setUser({...user, loggedIn});
    //   }
    // })

    // if (loading) return <p>loading</p>;
    // if (error) return <p>Error</p>
  return (
    // Grid Wrapper
    <ApolloProvider client={client(navigate)}>
      
        <FauxApp />
      

   { /* <>
    
    <div className="mb-4 flex flex-row gap-2">
      <Link to="/">Homepage</Link>
     
      {
        authToken && <Link to="/settings">Settings</Link>
      }

      {
        authToken ?
        <button onClick={handleLogout}>Logout</button>
        :
         <Link to="/login">Login</Link>
      }
    </div>
    <div className="grid grid-cols-4 w-full h-full">

      <Routes>
        <Route path="/login" element={ <Login />} />
        <Route path="/settings" element={<RequireAuth><Settings /></RequireAuth> }/>
        <Route path="user/:username" element={<Homepage/>} />
        <Route path="/" element={<div>Mediakit</div>} />
        <Route path="*" element={<div>There's nothing here :(</div>} />
      </Routes>
       


    </div>
    </> */}
     </ApolloProvider>
  );
}

export default App;
