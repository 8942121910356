import { useQuery } from "@apollo/client";
import { useContext } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { UserContext } from "./App";
import Loading from "./components/Loading";
import { LOGIN_CHECK } from "./queries";

export const RequireAuth = ({children}) => {
    const location = useLocation();

    const {loading, error, data}  = useQuery(LOGIN_CHECK);
    
    if (loading) return <div className="w-full h-full flex justify-center items-center"><Loading /></div>;
    if (error) return <div>Error with auth</div>
    if (!data.isLoggedIn.loggedIn){
        return <Navigate to="/login" state={{from : location}} replace />
    }
    return children;
}
