

export const TwoPanel = ({leftTitle, leftChild, rightTitle, rightChild}) => {
    return (
        <div className="w-full col-span-full  border rounded grid  grid-cols-1 md:grid-cols-2 md:grid-rows-1 ">
                <div className="md:row-start-0 md:col-start-1 h-64 col-span-full md:col-span-1   border-b md:border-none flex flex-col">
                            
                    <h3 className="shrink-0 text-sm border-b p-2 mb-1 font-bold">{rightTitle}</h3>
                    
                    <div className="grow flex flex-wrap gap-2 w-full p-2 content-start mx-auto h-full overflow-y-auto">
                    {
                    rightChild
                    }
                    </div>
                   
                </div>
                
                <div className="md:row-start-0 md:col-start-2 h-64 col-span-full md:col-span-1 border-l-0  md:border-l  flex flex-col">
                    
                    <h3 className="shrink-0 text-sm border-b p-2 mb-1 font-bold">{leftTitle}</h3>
                    
                    <div className="grow flex flex-wrap gap-2 w-full p-2 content-start mx-auto h-full overflow-y-auto ">
                    {
                        leftChild
                    }
                    </div>
                   
                </div>

                
        </div>
    )
}