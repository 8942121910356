import { useState, useEffect } from 'react';

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

export default function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}

export function useElementDimensions(ref){
    const [windowDimensions, setWindowDimensions] = useState(()=>{
        let width = 0;
        let height = 0;
        if (ref.current){
            width =  ref.current.offsetWidth;
            height = ref.current.offsetHeight;
        }

        return {
            ref,
            width,
            height
        }
    })

    useEffect(() => {
        function handleResize() {
            // console.log("Resizing")
            if (ref.current){
                console.log(ref.current.offsetWidth)
                setWindowDimensions((oldDimension)=>({...oldDimension, width: ref.current.offsetWidth, height: ref.current.offsetHeight}))
            }
        }
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        }
    }, [])

    return windowDimensions;
}