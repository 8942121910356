
import { ExclamationCircleIcon } from '@heroicons/react/solid'
import { FOCUS_BORDER_COLOR, FOCUS_RING_COLOR } from '../constants';


function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }


export const nonEmptyEntry = str => {
    return str.trim().length > 0 && str.trim() === str;
}

export const validEmail = (email) => {
    const res = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return res.test(String(email).toLowerCase());
}

export const validTwoDecimal = str => {
   const res = /^\d+(?:\.\d{2})$/;
   return res.test(String(str));

}
export const emptyEntry = str => {
    return String(str).length === 0
}


export const validateEmail = (str)  => {
    const res = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    return res.test(String(str));
}



export const validHttpUrl = str => {
    // const res = /[-a-zA-Z0-9@:%_\+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_\+.~#?&//=]*)?/gi;
    const res =  /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;

    return res.test(String(str));
}

export const autoFormatHttpUrl = str => {
    const httpHeader = "https://"
    const validUrl = validHttpUrl(str);
    const notEmpty = nonEmptyEntry(str);
    const notStartWithHttp = str.indexOf("http://") !== 0 && str.indexOf("https://");
    if (validUrl && notEmpty && notStartWithHttp){
        return httpHeader.concat(str);
    } else {
        return str;
    }
}



const standardInputStyling = `appearance-none rounded  block w-full py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none ${FOCUS_RING_COLOR} ${FOCUS_BORDER_COLOR} sm:text-sm relative px-3`;

const InputContainer = ({children,  valid, invalidMessage,  header, screenReadOnly, className, inputContainerClass, value, maxChar, hideCount, charCountAppear}) => {
    const validRender = valid === true || valid === false;
    

    return (
        <div className={className}>
            <label className={classNames(screenReadOnly? "sr-only" : "", header ? "block text-sm font-medium text-blue-gray-900": "")}>
                {header}
            </label>
            <div className={classNames("mt-1 relative", inputContainerClass? inputContainerClass: "")}>
                
                
                {children}
                
           
                { validRender && !valid &&
                    <>
                    <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none ">
                        <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
                    </div>

                    
                    </>

                }
                
            
            </div>
            {!hideCount && <CharacterCount str={value} maxChar={maxChar} visible={charCountAppear}/>}
            { validRender &&  
                <p className={classNames("mt-2 text-sm text-red-600", valid? "invisible": "")}>
                    {Array.isArray(invalidMessage) ? invalidMessage[0]: invalidMessage}
                </p>
            }
        </div>
    ) 
}

export const Input = ({value, onChange, valid, invalidMessage, placeholder, header, screenReadOnly, className, frontElement, maxChar, charCountAppear, onBlur, type}) => {
    const validRender = valid === true || valid === false;
    
    return (
        <InputContainer {...{valid, invalidMessage, header, screenReadOnly, className, frontElement, value, maxChar, charCountAppear}}>
        {
            frontElement &&
            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <span className="text-gray-500 sm:text-sm">{frontElement}</span>
            </div>
        }
        
        <input
            className={classNames(`ppearance-none rounded  block w-full py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none ${FOCUS_RING_COLOR} ${FOCUS_BORDER_COLOR}  sm:text-sm`,
                !frontElement? "relative px-3": "pl-7 pr-12"
            )}
            placeholder={placeholder}
            value={value}
            type={type ?? "text"}
            onChange={onChange}
            onBlur={onBlur}
        />
        
        </InputContainer>
       
    )

};

export const InputAddOn = ({value, onChange, valid, invalidMessage, placeholder, header, screenReadOnly, className, addOnValue, maxChar, charCountAppear, onBlur}) => {
    // Parse and remove everything starting with rounded
    const inputStyling = standardInputStyling.split(/\s+/).filter(str=>!str.startsWith("rounded")).join(" ")
    const inputContainerClass = "flex"
    return (
        <InputContainer {...{ valid, invalidMessage, header, screenReadOnly, className,inputContainerClass, value, maxChar, charCountAppear}}>
            
           
            <span className="inline-flex items-center px-3 rounded-l-md border border-r-0 border-blue-gray-300 bg-blue-gray-50 text-blue-gray-500 sm:text-sm">
                {addOnValue}
            </span>
            <input
                type="text"
                name="alias"
                id="alias"
                autoComplete="username"
                className={classNames(inputStyling, "rounded-none rounded-r-md flex-1")}
                value={value}
                onChange={onChange}
                placeholder={placeholder}
                onBlur={onBlur}
            />
           
            
        </InputContainer>
    )
}

export const Textarea = ({value, onChange, valid, invalidMessage, placeholder, header, screenReadOnly, className, rows, description, maxChar, charCountAppear, onBlur}) => {
    return (
        <InputContainer {...{valid, invalidMessage, placeholder, header, screenReadOnly, className, hideCount: true}}>
            <textarea value={value} onChange={onChange} rows={rows} className={classNames(standardInputStyling, "resize-none")} placeholder={placeholder} onBlur={onBlur} />
            {   
                (description || maxChar) &&
                <div className='flex mt-2 justify-end'>
                    {description && <p className=" text-sm text-gray-500 grow" >{description}</p>}
                    <CharacterCount str={value} maxChar={maxChar} visible={charCountAppear}/>
                </div>
            }
        </InputContainer>
    )
}


const CharacterCount = ({str, maxChar, className, visible}) => {
    const stringSize = str ? str.length: 0;
    const limit = maxChar? maxChar : 0
    const color = stringSize > limit ? "text-red-600" : "text-gray-500"
    return (
        maxChar ? 
        <div className={classNames("text-right text-sm text-gray-500 shrink-0", visible? "" : "invisible", color, className)}>
            {stringSize} / {limit}
        </div>
        : 
        null
        
    )
}