import { FOCUS_RING_COLOR, HOVER_PRIMARY_BG_COLOR, PRIMARY_BG_COLOR } from "../constants";
import { classNames } from "../uitils"

export const Button = ({type, children, onClick, className})=>{
    switch(type){
        case "PRIMARY":
            return (
                <button
                    className={classNames(`inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white ${PRIMARY_BG_COLOR} ${HOVER_PRIMARY_BG_COLOR} focus:outline-none focus:ring-2 focus:ring-offset-2 ${FOCUS_RING_COLOR}`, className)}
                    onClick={onClick}
                >
                    {children}
                </button>
            )

        case "SECONDARY":
            return(
            <button type="button" className={classNames(`bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-blue-gray-900 hover:bg-blue-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 ${FOCUS_RING_COLOR}`, className)}
                onClick={onClick}
            >
                    {children}
                </button>
            )
        
        default:
            throw Error("Invalid button Type")
    }
    
};