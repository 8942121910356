import { useState } from "react"

export const useObjectState = (initializer) => {
    const [obj, setObj] = useState(initializer);

    const setValue = (key, value) => {
        let newObj = {...obj};
        newObj[key] = value;
        setObj(newObj);
    }

    const onChange = attr => {
        return e => {
            setValue(attr, e.target.value)
            // let newObj = {...obj};
            // newObj[attr] = e.target.value;
            // setObj(newObj);
        }
    }

    return [obj, setObj, onChange, setValue];
}

export const useArrayObjectState = (initializer) => {
    const [arr, setArr] = useState(initializer);

    const setValue = (attr, newValue, key, value) => {
        const index = arr.findIndex(element => element[key] === value);
        if (index > -1){
            let newObj = {...(arr[index])}
            newObj[attr] = newValue;
            setArr([
                ...(arr.slice(0, index)),
                newObj,
                ...(arr.slice(index + 1, arr.length))
            ])
        }
    }

    const onChange = (attr, key, value) => {
        return e => {
            setValue(attr, e.target.value, key, value);
            // const index = arr.findIndex(element => element[key] === value);
            // if (index > -1){
            //     let newObj = {...(arr[index])}
            //     newObj[attr] = e.target.value;
            //     setArr([
            //         ...(arr.slice(0, index)),
            //         newObj,
            //         ...(arr.slice(index + 1, arr.length))
            //     ])
            // }
        }
    }

    const getValue = (attr, key, value) => {
        const obj = arr.find(element =>element[key] === value);
        if (obj) {
            return obj[attr]
        }
    }

    return [arr, setArr, onChange, getValue, setValue];
}