import { useQuery } from "@apollo/client";
import { PlusCircleIcon } from "@heroicons/react/outline";
import { GET_SETTINGS } from "../../queries";
import Loading from "../Loading";

const Badge = ({children}) => (
    <span className="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-green-100 text-green-800">
        {children}
    </span>
)

const IntegrationsTab = () => {
    const integratedPlatforms = ["Youtube"]
    const {loading, error, data} = useQuery(GET_SETTINGS);

    if (loading) return <div className="w-full h-full"><Loading/></div>;
    if (error) return <p>Error:/ at Integrations</p>;
    
    const platforms = data.settings.platforms.filter(platform=>integratedPlatforms.indexOf(platform.name) > -1);
    
    return (
        <div className="flex-1 xl:overflow-y-auto">
            <div className="max-w-3xl mx-auto py-10 px-4 sm:px-6 lg:py-12 lg:px-8">
                  <h1 className="text-3xl font-extrabold text-blue-gray-900">Integrations</h1>
        
                    <div className="mt-6 space-y-8 divide-y divide-y-blue-gray-200">
                    <div className="grid grid-cols-1 gap-y-2 sm:grid-cols-2 sm:gap-x-6">

                        {
                            platforms.map(platform=>{return(
                            <div key={platform.name}
                            className="flex flex-col justify-center items-center relative h-32  w-full border-2 border-gray-300  rounded-lg p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                            >
                            
                            <span className="mt-2 block  font-bold text-gray-900">{platform.name}</span>
                            {
                                platform.connected? <Badge>Connected</Badge>
                                : 
                                <a href="http://localhost:8080/">Connect</a>
                            }
                            
                            
                        </div>)}
                        )
                        
                        }
                        



                    </div>
                    </div>
            </div>
        </div>
    )
}

export default IntegrationsTab;