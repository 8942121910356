import ApplicationShell from "./components/ApplicationShell";
import { DividerTitle } from "./components/Divider";
import QuickView from "./components/QuickView";
import React from "react";
import photo1 from "./media/img1.jpeg";
import photo2 from "./media/img2.jpeg";
import photo3 from "./media/img3.jpeg";
import photo4 from "./media/profile.jpeg";
import SlideOver from "./components/SlideOver";

import { CameraIcon } from "@heroicons/react/outline";
import { Navigate, Route, Routes, useParams } from "react-router-dom";
import SettingsTemplate  from "./components/Settings";
import { GET_HOMEPAGE, LOGIN_CHECK } from "./queries";
import { ApolloProvider, useLazyQuery, useQuery } from "@apollo/client";
import { Transition } from "@headlessui/react";
import Login, { loginTypes } from "./components/Login";
import { RequireAuth } from "./RequireAuth";
import { client, UserContext } from "./App";

import { motion } from "framer-motion"
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/solid";
import useWindowDimensions, { useElementDimensions } from "./useWindowDimensions";
import { classNames, convertRemToPixels } from "./uitils";
import Loading from "./components/Loading";
import ErrorPage from "./components/ErrorPage";
import LandingPage from "./components/LandingPage";
import { FOCUS_RING_COLOR, HOVER_PRIMARY_BG_COLOR, HOVER_PRIMARY_TEXT_COLOR, PRIMARY_BG_COLOR, PRIMARY_TEXT_COLOR } from "./constants";

const photos = ["img1", "img2", "img3", "profile"].map(str => `./media/${str}.jpeg`);



const photolist = [photo1, photo2, photo3, photo4, photo2, photo3, photo1, photo4, photo4];

const socialComponents = {
  "Facebook":{
      name: 'Facebook',
      href: '#',
      icon: (props) => (
        <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
          <path
            fillRule="evenodd"
            d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
            clipRule="evenodd"
          />
        </svg>
      ),
    },
  "Instagram":{
      name: 'Instagram',
      href: '#',
      icon: (props) => (
        <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
          <path
            fillRule="evenodd"
            d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
            clipRule="evenodd"
          />
        </svg>
      ),
    },
  "Twitter":{
      name: 'Twitter',
      href: '#',
      icon: (props) => (
        <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
          <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
        </svg>
      ),
    },
    
  "Tiktok": {
      name: "Tiktok",
      href: "#",
      icon: (props) => (
        <svg  viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" {...props}>
          <path d="M16.708 0.027c1.745-0.027 3.48-0.011 5.213-0.027 0.105 2.041 0.839 4.12 2.333 5.563 1.491 1.479 3.6 2.156 5.652 2.385v5.369c-1.923-0.063-3.855-0.463-5.6-1.291-0.76-0.344-1.468-0.787-2.161-1.24-0.009 3.896 0.016 7.787-0.025 11.667-0.104 1.864-0.719 3.719-1.803 5.255-1.744 2.557-4.771 4.224-7.88 4.276-1.907 0.109-3.812-0.411-5.437-1.369-2.693-1.588-4.588-4.495-4.864-7.615-0.032-0.667-0.043-1.333-0.016-1.984 0.24-2.537 1.495-4.964 3.443-6.615 2.208-1.923 5.301-2.839 8.197-2.297 0.027 1.975-0.052 3.948-0.052 5.923-1.323-0.428-2.869-0.308-4.025 0.495-0.844 0.547-1.485 1.385-1.819 2.333-0.276 0.676-0.197 1.427-0.181 2.145 0.317 2.188 2.421 4.027 4.667 3.828 1.489-0.016 2.916-0.88 3.692-2.145 0.251-0.443 0.532-0.896 0.547-1.417 0.131-2.385 0.079-4.76 0.095-7.145 0.011-5.375-0.016-10.735 0.025-16.093z"/>
        </svg>
      )
    }
    ,
}

const socials = [
    {
      name: 'Facebook',
      href: '#',
      icon: (props) => (
        <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
          <path
            fillRule="evenodd"
            d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
            clipRule="evenodd"
          />
        </svg>
      ),
    },
    {
      name: 'Instagram',
      href: '#',
      icon: (props) => (
        <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
          <path
            fillRule="evenodd"
            d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
            clipRule="evenodd"
          />
        </svg>
      ),
    },
    {
      name: 'Twitter',
      href: '#',
      icon: (props) => (
        <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
          <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
        </svg>
      ),
    },
    {
      name: 'GitHub',
      href: '#',
      icon: (props) => (
        <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
          <path
            fillRule="evenodd"
            d="M12 2C6.477 2 2 6.484 2 12.017c0 4.425 2.865 8.18 6.839 9.504.5.092.682-.217.682-.483 0-.237-.008-.868-.013-1.703-2.782.605-3.369-1.343-3.369-1.343-.454-1.158-1.11-1.466-1.11-1.466-.908-.62.069-.608.069-.608 1.003.07 1.531 1.032 1.531 1.032.892 1.53 2.341 1.088 2.91.832.092-.647.35-1.088.636-1.338-2.22-.253-4.555-1.113-4.555-4.951 0-1.093.39-1.988 1.029-2.688-.103-.253-.446-1.272.098-2.65 0 0 .84-.27 2.75 1.026A9.564 9.564 0 0112 6.844c.85.004 1.705.115 2.504.337 1.909-1.296 2.747-1.027 2.747-1.027.546 1.379.202 2.398.1 2.651.64.7 1.028 1.595 1.028 2.688 0 3.848-2.339 4.695-4.566 4.943.359.309.678.92.678 1.855 0 1.338-.012 2.419-.012 2.747 0 .268.18.58.688.482A10.019 10.019 0 0022 12.017C22 6.484 17.522 2 12 2z"
            clipRule="evenodd"
          />
        </svg>
      ),
    },
    {
      name: 'Dribbble',
      href: '#',
      icon: (props) => (
        <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
          <path
            fillRule="evenodd"
            d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10c5.51 0 10-4.48 10-10S17.51 2 12 2zm6.605 4.61a8.502 8.502 0 011.93 5.314c-.281-.054-3.101-.629-5.943-.271-.065-.141-.12-.293-.184-.445a25.416 25.416 0 00-.564-1.236c3.145-1.28 4.577-3.124 4.761-3.362zM12 3.475c2.17 0 4.154.813 5.662 2.148-.152.216-1.443 1.941-4.48 3.08-1.399-2.57-2.95-4.675-3.189-5A8.687 8.687 0 0112 3.475zm-3.633.803a53.896 53.896 0 013.167 4.935c-3.992 1.063-7.517 1.04-7.896 1.04a8.581 8.581 0 014.729-5.975zM3.453 12.01v-.26c.37.01 4.512.065 8.775-1.215.25.477.477.965.694 1.453-.109.033-.228.065-.336.098-4.404 1.42-6.747 5.303-6.942 5.629a8.522 8.522 0 01-2.19-5.705zM12 20.547a8.482 8.482 0 01-5.239-1.8c.152-.315 1.888-3.656 6.703-5.337.022-.01.033-.01.054-.022a35.318 35.318 0 011.823 6.475 8.4 8.4 0 01-3.341.684zm4.761-1.465c-.086-.52-.542-3.015-1.659-6.084 2.679-.423 5.022.271 5.314.369a8.468 8.468 0 01-3.655 5.715z"
            clipRule="evenodd"
          />
        </svg>
      ),
    },
    {
      name: "Tiktok",
      href: "#",
      icon: (props) => (
        <svg  viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" {...props}>
          <path d="M16.708 0.027c1.745-0.027 3.48-0.011 5.213-0.027 0.105 2.041 0.839 4.12 2.333 5.563 1.491 1.479 3.6 2.156 5.652 2.385v5.369c-1.923-0.063-3.855-0.463-5.6-1.291-0.76-0.344-1.468-0.787-2.161-1.24-0.009 3.896 0.016 7.787-0.025 11.667-0.104 1.864-0.719 3.719-1.803 5.255-1.744 2.557-4.771 4.224-7.88 4.276-1.907 0.109-3.812-0.411-5.437-1.369-2.693-1.588-4.588-4.495-4.864-7.615-0.032-0.667-0.043-1.333-0.016-1.984 0.24-2.537 1.495-4.964 3.443-6.615 2.208-1.923 5.301-2.839 8.197-2.297 0.027 1.975-0.052 3.948-0.052 5.923-1.323-0.428-2.869-0.308-4.025 0.495-0.844 0.547-1.485 1.385-1.819 2.333-0.276 0.676-0.197 1.427-0.181 2.145 0.317 2.188 2.421 4.027 4.667 3.828 1.489-0.016 2.916-0.88 3.692-2.145 0.251-0.443 0.532-0.896 0.547-1.417 0.131-2.385 0.079-4.76 0.095-7.145 0.011-5.375-0.016-10.735 0.025-16.093z"/>
        </svg>
      )
    }
    ,
]

// const metrics = [
//     {qualifier: "Followers", quantifier: "23 K", id: 1},
//     {qualifier: "Subscribers", quantifier: "10 M", id: 2},
//     {qualifier: "Videos", quantifier: 50, id: 3},
//     {qualifier: "Followers", quantifier: "23 K", id: 4},
//     {qualifier: "Subscribers", quantifier: "10 M", id: 5},
//     {qualifier: "Videos", quantifier: 50, id: 6},
// ]



const Homepage2 = () => {

  const params = useParams();
  const username = params.username ?? "jordan"

  const [openSlider, setOpenSlider] = React.useState(false);

  const {data, loading, error} = useQuery(GET_HOMEPAGE, {variables: {username: username }});
  if (loading) return <div className="w-full h-full flex justify-center items-center"><Loading /></div>
  if (error) {console.log(error); return <Navigate to="notFound" replace />}

  const {firstName, lastName} = data.homepage.user;
  const {bio, influencerType, imageUrl} = data.homepage.profile;
  const {metrics, services, media, platforms} = data.homepage;

  const activeSocials = platforms.filter(platform => platform.name in socialComponents)

  const displayGallery = media.length > 0;
  return (
      <ApplicationShell padding={false}>
          {services.length > 0 && <SlideOver open={openSlider} setOpen={setOpenSlider} title={"Services"}>
            <div className="flex flex-col gap-2">
            {services.map(service=>(
              <ServiceElement key={service.id} service={service}/>
              ))}
              </div>
          </SlideOver>}
          {/* Header for the profile or whatever */}
          <div className="grid grid-cols-1 md:grid-cols-3 gap-2  mb-6 h-full">
              
            {displayGallery && 
              <div className=" hidden md:block col-start-1 overflow-y-hidden py-5">
                <PhotoGallery images={media}/>
              </div>
            }

              <div className={classNames(displayGallery? " md:col-start-2 col-span-2": "col-span-full", " col-start-1 py-5 overflow-y-auto px-2 md:px-0")}>
                  <div className="grid grid-cols-1 md:grid-cols-3 md:px-1 gap-5">
                    <div className={`hidden ${PRIMARY_BG_COLOR} h-48 w-48 shrink-0 col-span-full lg:col-span-1 m-auto`} />

                    <div className="col-span-full m-auto w-full">

                      <div className="flex gap-2 mb-2">
                        <div className={`h-32 w-32 ${PRIMARY_BG_COLOR} shrink-0 bg-contain`} style={{backgroundImage: imageUrl? `url(${imageUrl})`: undefined}}></div>
                        <div className="grow-1">
                          <h1 className="text-5xl font-bold">{firstName} {lastName}</h1>
                          <h3 className="text-neutral-600 mb-2">{influencerType}</h3>
                          {activeSocials.length > 0 && <div className="flex gap-2 mb-2">
                            {
                              activeSocials.map((social) => {
                                const item = socialComponents[social.name]
                                return(
                                  <a key={item.name} href={social.userLink} target="_blank" className="text-gray-400 hover:text-gray-500">
                                      <span className="sr-only">{item.name}</span>
                                      <item.icon className="h-6 w-6" aria-hidden="true" />
                                  </a>
                              )})
                            }
                          </div>}

                          
                  
                        </div>
                      </div>
                      

                      <div className="">
                        {
                          bio.length > 0 && 
                          <>
                          <div className="uppercase font-bold text-neutral-500">About Me</div>
                          <p className="mb-2">{bio}</p>
                          </>
                        }
                        {services.length > 0 && <button
                          type="button"
                          className={`inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded shadow-sm text-white ${PRIMARY_BG_COLOR} ${HOVER_PRIMARY_BG_COLOR} focus:outline-none focus:ring-2 focus:ring-offset-2 ${FOCUS_RING_COLOR}`}
                          onClick={()=>setOpenSlider(true)}
                        >
                            Services
                          </button>}
                      </div>

                  </div>
                  <hr className="col-span-full"/>
                  {displayGallery && <div className="col-span-full overflow-hidden md:hidden">
                    <div className="uppercase font-bold text-neutral-500">Gallery</div>
                    <PhotoGallery images={media}/>
                  </div>}
                  
                  { (metrics.length > 0) &&
                    <div className="col-span-full">
                      <div className="font-bold uppercase text-neutral-500 mb-2">Notables</div>

                      <div className="grid gap-3 py-2 grid-cols-3">
                          {
                              metrics.map(({quantifier, qualifier, id}) => (
                                  <div key={id} className="border  rounded px-2 py-1 shrink-0 text-center col-span-1">
                                      <div className="text-2xl font-bold">{quantifier}</div>
                                      <div className="text-lg">{qualifier}</div>
                                  </div>
                              ))
                          }
                        </div>
                    </div>
                  }
                    </div>
                  
              </div>

              

              
          </div>

         
          
          
      </ApplicationShell>
  )
}


const Settings = () => {
    return (
        <ApplicationShell>
            <SettingsTemplate />
        </ApplicationShell>
    )
}

const FauxApp = () => {
    // const {user, setUser} = React.useContext(UserContext);
    const [user, setUser] = React.useState({loggedIn: false, username: undefined});
    // console.log("rendering");
     const {loading, error}  = useQuery(LOGIN_CHECK, {
      onCompleted: data => {
        const {loggedIn, username, imageUrl, user} = data.isLoggedIn;
        setUser({username, loggedIn, imageUrl, ...user});
      },
      onError: err => {
        setUser({username: null, loggedIn: false, imageUrl: null})
      }
    });

   
    if(loading) return <div className="w-full h-full flex justify-center items-center"><Loading /></div>
    if (error) return <p>Error at FauxApp</p>;
    
    return (
        <>
        <UserContext.Provider value={{user, setUser}}>
          <Routes>
              <Route path="/" element={<LandingPage/>} />
              <Route path="/login" element={<Login loginState={loginTypes.LOGIN}/>} />
              <Route path="/register" element={<Login loginState={loginTypes.REGISTER}/>} />
              <Route path="/settings" element={<RequireAuth><Settings /></RequireAuth>} />
              <Route path="/notFound" element={<ErrorPage/>} />
              <Route path="/:username" element={<Homepage2 />} />

              
              <Route path="*" element={<ErrorPage/>} />
          </Routes>
        </UserContext.Provider>
        </>
    )
}

const PhotoGallery = ({images}) => {
  const [isShowing, setIsShowing] = React.useState(false);
  const arr = Array(4).fill(images).flat();
  const scrollRef = React.useRef();
  
  const windowDimensions = useWindowDimensions();
  const tileDimensions = useElementDimensions(scrollRef);

  let num_tiles;

  let num_y = Math.floor((windowDimensions.height - 1 * convertRemToPixels(8)) / convertRemToPixels(8));
  let num_x = Math.floor((windowDimensions.width < 1300? windowDimensions.width : 1300) / 3  / convertRemToPixels(8));
  if(windowDimensions.width < 660){
      num_tiles = 15;
  }

  if (windowDimensions.height >= 640) {
    num_y = 4;
  }

  num_tiles = num_y * num_x;

  
  // Cut off the number of active tiles based on vh
  // if (windowDimensions.height > 819) {num_tiles = 15}
  // else if (windowDimensions.height > 680) {num_tiles = 12}
  // else if (windowDimensions.height > 535) {num_tiles = 9}
  // else {num_tiles = 6}
  // num_tiles = 15;
  // Make sure there are num_tiles images
  let imageList = [...images];
  if(imageList.length < num_tiles){
    // Number of times to repeat the array
    let repeater = Math.ceil(num_tiles / imageList.length);
    imageList = Array(repeater).fill(imageList).flat().slice(0, num_tiles);
  }




  // const durations = Array.from({length: 15}, (e, i) => i + 1);
  // // const durationList = durations.sort(() => Math.random() - 0.5);
  // // const durationList = []
  // let durationList = Array.from({length: num_tiles}, (e, i) => (i + 1) + i * 8)
  // durationList = durationList.sort(() => Math.random() - 0.5);

  // console.log(durationList)

  const scroll = (shift) => {
    if (scrollRef.current){
      const width = scrollRef.current.offsetWidth;
      // scrollRef.current.scrollLeft += shift;
      // console.log(scrollRef.current.scrollBy)
      scrollRef.current.scrollBy({
        left: shift === "LEFT" ? -width : width,
        behavior: "smooth"
      })
    }
  }

  
  return (
    <>
    {/* <div>{windowDimensions.width}</div> */}
    <div
      className="flex md:block items-center min-h-0"
      
    >

      
      <button className="h-full w-5 text-blue-gray-400 md:hidden shrink-0" onClick={()=>scroll("LEFT")}><ChevronLeftIcon /></button>
      <div className="flex grow overflow-hidden  md:h-full md:grid md:grid-cols-2 lg:grid-cols-3 md:gap-2 overflow-y-hidden overflow-x-hidden items-center " ref={scrollRef}>
      {
        imageList.map((ele, index)=>{
          let delay_time = index % 2 + 1 ;
          // I want to coordinate delay times
          // For element i, I want element i + 2 to go at the same time
          return <FlipElement delay={delay_time} url={ele.url} key={index} startFront={index % 2 == 0}/>
          // return <div className="w-1/2 sm:w-1/4 md:w-full h-32 grow-1 shrink-0 bg-red-200"/>
          // return <div className="w-32 h-32 bg-red-400 grow-1 shrink-0" key={ele}/>
        })
      }
      </div>
      <button className="h-5 w-5 text-blue-gray-400 md:hidden shrink-0" onClick={()=>scroll("RIGHT")}><ChevronRightIcon /></button>
      {/* {
        arr.map(({url, id})=>{

          return (
            <>
              <div className=" shrink-0 h-1/2 md:h-full col-span-1 bg-contain  bg-center aspect-1 "
                style={{backgroundImage:`url(${url})`}}
              />
            </>
      
          )
        })
      } */}
      
    </div>
    {/* <div className="md:hidden text-center">
      <button className="h-5 w-5 text-blue-gray-400" onClick={()=>scroll("LEFT")}><ChevronLeftIcon /></button>
      <button className="h-5 w-5 text-blue-gray-400" onClick={()=>scroll("RIGHT")}><ChevronRightIcon /></button>
    </div> */}
    </>
    )
}

const FlipElement = ({delay, url, startFront}) => {
  // const imgFront = images[Math.floor(Math.random() * images.length)];
  // const imgBack = images[Math.floor(Math.random() * images.length)];
  const backgroundImage = url ?  `url(${url})`: undefined;
  const duration = 10;
  const animaitonFront ={rotateY: ["0deg", "180deg", "180deg", "0deg"],}
  const animaitonBack ={rotateY: ["180deg", "0deg", "0deg", "180deg"],}
  const transition = {
    duration,
    ease: "easeInOut",
    repeat: Infinity,
    repeatDelay: 10,
    times: [0, 1/duration, (duration-1)/duration, 1],
    delay
  }

  return (<div className="w-32 h-32 grow-1 shrink-0 mx-auto">
    {/* Inner */}
    <div className="w-full h-full relative ">
      {/* Front */}
      <motion.div className=" hidden md:block w-full h-full absolute backface-hidden bg-slate-500 bg-cover"
      style={{backgroundImage}}
      animate={startFront? animaitonFront: animaitonBack}
      transition={transition}
      />
      {/* Back */}
      <motion.div className={`hidden md:block w-full h-full absolute backface-hidden ${PRIMARY_BG_COLOR} bg-cover`}
      // style={{backgroundImage}}
      animate={startFront? animaitonBack: animaitonFront}
      transition={transition}
      />
      {/* Static */}
      <div className="md:hidden w-full h-full absolute backface-hidden bg-slate-500 bg-cover"
      style={{backgroundImage}} 
      />
    </div>
  </div>
)}

const ServiceElement = ({service}) => {
  const {name, description, price, fullDescription} = service;

  const [expanded, setExpanded] = React.useState(false);
  const existingFullDescription = fullDescription.length > 0;
  return (
    <div className="border rounded p-3 ">
        <div className="flex gap-2">
            <div className="grow flex content-between items-center">
                <h2 className="text-lg font-extrabold text-gray-900 grow">{name}</h2>
                <p className="shrink-0">${price.toFixed(2)}</p>
            </div>
        </div>
        <hr className="my-2"/>
        <p>{description}</p>
       
        {
          expanded && existingFullDescription &&
          <>
            <p>{fullDescription}</p>
          </>
          
        }
        {existingFullDescription &&
        <p>
            <button className={`font-medium ${PRIMARY_TEXT_COLOR} ${HOVER_PRIMARY_TEXT_COLOR}`}
              onClick={()=>setExpanded(!expanded)}
            >
              {expanded? "Show Less" : "View full details"}
            </button>
        </p>}
    </div>
  )

}

export default FauxApp;