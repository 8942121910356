import { Dialog, Transition } from "@headlessui/react"
import React, {Fragment} from "react";
import { CheckIcon, PlusCircleIcon, PlusIcon } from '@heroicons/react/outline'
import { useMutation, useQuery } from "@apollo/client";
import { ADD_SERVICE, DELETE_SERVICE, GET_SETTINGS, UPDATE_SERVICE } from "../../queries";
import { Input, InputLeftElement, nonEmptyEntry, Textarea, validTwoDecimal } from "../Inputs";
import Notification from "../Notification";
import { Button } from "../Buttons";
import { useObjectState } from "../StateHooks";
import { FormRender } from "./FormRender";
import { FOCUS_RING_COLOR, PRIMARY_TEXT_COLOR } from "../../constants";

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }

const MAX_CHAR_DESCRIPTION = 100;
const MAX_CHAR_FULL_DESCRIPTION = 300;
const MAX_CHAR_TITLE = 30;

const nonEmptyError = {func: nonEmptyEntry, message: "Field must not be empty"};
const lengthError = (count) => ({func: str=>str.length <= count, message: "Field is too long"});

const ServiceTab = () => {
    const handleSaveButton = () => {}
    const [open, setOpen] = React.useState(false);
    const [services, setServices] = React.useState([]);
    const [formProps, setFormProps] = React.useState({type: "ADD"})
    
    const [notificationButton, setNotificationButton] = React.useState("ERROR");
    const [showNotification, setShowNotification] = React.useState(false);

    useQuery(GET_SETTINGS, {
        variables: {userId: 1},
        onCompleted: data => {
            setServices(data.settings.services)
        }
    })

    const handleNewButton = () => {
      setFormProps({type: "ADD"});
      setOpen(true);
    }

    const handleEditButton = (service) => {
      setFormProps({type: "EDIT", service})
      setOpen(true);
    }

    const title = formProps.type === "ADD" ? "New Service" : "Edit Service"

    return (
        <>
        {<Notification show={showNotification} setShow={setShowNotification} alertType={notificationButton}/>}
        <MyModal isOpen={open} setIsOpen={setOpen} title={title} setShowNotification={setShowNotification}  setNotificationButton={setNotificationButton}>
          <Form {...formProps} setOpen={setOpen}/>
        </MyModal>
        <div className="flex-1 xl:overflow-y-auto">
                  <div className="max-w-3xl mx-auto py-10 px-4 sm:px-6 lg:py-12 lg:px-8">
                  <h1 className="text-3xl font-extrabold text-blue-gray-900">Services</h1>
      
                  <div className="mt-6 space-y-8 divide-y divide-y-blue-gray-200">
                   

                        <div className="grid grid-cols-1 gap-y-2 sm:grid-cols-2 sm:gap-x-6">

                            <button
                            type="button"
                            className={`flex flex-col justify-center items-center relative h-32  w-full border-2 border-gray-300 border-dashed rounded-lg p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 ${FOCUS_RING_COLOR}`}
                            onClick={handleNewButton}
                            >
                                <div><PlusCircleIcon className="-ml-1 mr-2 h-7 w-7" /></div>
                                <span className="mt-2 block text-sm font-medium text-gray-900">Create a new service</span>
                            </button>

                            {
                                services.map((service)=>{
                                  const {id, name, description, price} = service; 
                                  return (
                                    <div key={id} className="grid-span-1  border rounded p-2 flex flex-col justify-center items-center h-32">
                                        <div className="text-xl font-bold">{name}</div>    
                                        <div className="text-slate-900">${price.toFixed(2)}</div>
                                        <div className="text-sm text-center">{description}</div>
                                        <button className={`${PRIMARY_TEXT_COLOR} font-bold`} onClick={()=>handleEditButton(service)}>
                                            Edit 
                                        </button>
                                    </div>
                                )})
                            }
                            
                        </div>
                  </div>

                  </div>
              </div>
        </>
    )
}





/*
Name, sort desc, rate, long desc
*/
function MyModal({isOpen, setIsOpen, children, title}) {
    // let [isOpen, setIsOpen] = React.useState(true)
  
    function closeModal() {
      setIsOpen(false)
    }
  
    function openModal() {
      setIsOpen(true)
    }
  
    return (
      <>
  
        <Transition appear show={isOpen} as={Fragment}>
          <Dialog
            as="div"
            className="fixed inset-0 z-10 overflow-y-auto"
            onClose={closeModal}
          >
            <div className="min-h-screen h-screen px-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
              </Transition.Child>
  
              {/* This element is to trick the browser into centering the modal contents. */}
              <span
                className="inline-block h-screen align-middle"
                aria-hidden="true"
              >
                &#8203;
              </span>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <div className="inline-block w-full max-w-4xl h-3/4 p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    {title}
                  </Dialog.Title>
                  

                  {children}
  
                  
                </div>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition>
      </>
    )
  }



  const formTemplate = (service)=>([
    {
      sectionTitle: "Service",
      sectionDescription: "This information will be displayed publicly so be careful what you share.",
      inputs: [
       { 
          component: Input,
          name: "name",
          props:  {
            header: "Service Title",
            maxChar: MAX_CHAR_TITLE,
            charCountAppear: service.name.length > MAX_CHAR_TITLE - 10,
            className: "sm:col-span-3"
          },
          errors: [
            nonEmptyError,
            lengthError(MAX_CHAR_TITLE)
          ]
      },
      {
        component: Input,
        name: "price",
        props: {
          frontElement:"$",
          placeholder: "0.00",
          header: "Price",
          className: "sm:col-span-3"
        },
        errors: [
          nonEmptyError,
          {func: validTwoDecimal, message: "Price must be formatted correctly"}
        ]
      },
      {
        component: Textarea,
        name: "description",
        props: {
          header: "Short Description",
          rows: 3,
          maxChar: MAX_CHAR_DESCRIPTION,
          charCountAppear: true,
          className: "sm:col-span-6"
        },
        errors: [
          nonEmptyError,
          lengthError(MAX_CHAR_DESCRIPTION)
        ]
      },
      {
        component: Textarea,
        name: "fullDescription",
        props: {
          rows: 3,
          charCountAppear: true,
          maxChar: MAX_CHAR_FULL_DESCRIPTION,
          header: "Full Description",
          className: "sm:col-span-6"
        },
        errors: [
          nonEmptyError,
          lengthError(MAX_CHAR_FULL_DESCRIPTION)
        ]
      }


      ]
    }
  ])

  const Form = ({type, service, setOpen,  setNotificationButton, setShowNotification}) =>{
    const [newService, setNewService, onServiceChange, setServiceValue] = useObjectState(
      service ? {...service, price: service.price.toFixed(2)} : {
        name: "", price: "", description: "", quantifier: "", fullDescription: ""
      }
    );



    const handleChange = onServiceChange;
    const getValue = attr => newService[attr];
    const setValue = setServiceValue;
    const template = formTemplate(newService);


    const [addService] = useMutation(ADD_SERVICE);
    const [deleteService] = useMutation(DELETE_SERVICE);
    const [updatedService] = useMutation(UPDATE_SERVICE);

    const handleCancel = () => {
      setOpen(false);
    }

    const handleAddButton = () => {
      
        const variables = {
          service: {
            name: newService.name,
            price: parseFloat(newService.price),
            description: newService.description,
            fullDescription: newService.fullDescription,
            quantifier: newService.quantifier,
            platform: newService.platform || ""
          }
        }

        if (type === "ADD"){
          addService({variables, refetchQueries: [GET_SETTINGS]});
        } else {
          updatedService({variables: {...variables, serviceId: newService.id}, refetchQueries: [GET_SETTINGS],})
        }
        
        setOpen(false);
      
    }

    

    const handleDeleteButton = () => {

      deleteService({variables: {serviceId: service.id}, refetchQueries: [GET_SETTINGS],
        onCompleted: () =>{
          setNotificationButton("SUCCESS");
          setShowNotification(true);
      },
      onError: () => {
          setNotificationButton("ERROR");
          setShowNotification(true);
      }
      })
      setOpen(false);
    }

    const buttonTemplate = (() => {
      switch (type) {
        case 'ADD':
          return [
            {
              component: Button,
              props: {
                type: "SECONDARY",
                children: "Cancel",
                onClick: handleCancel
              }
            },
            {
             component: Button,
             type: "SUBMIT",
             props: {
               type: "PRIMARY",
               children: "Add",
               onClick: handleAddButton
             }
            }
          ];
        case 'EDIT':
          return [
            {
              component: Button,
              props: {
                type: "SECONDARY",
                children: "Delete",
                onClick: handleDeleteButton
              }
            },
            {
              component: Button,
              props: {
                type: "SECONDARY",
                children: "Cancel",
                onClick: handleCancel
              }
            },
            {
              component: Button,
              props: {
                type: "PRIMARY",
                children: "Save",
                onClick: handleAddButton
              }
            }
          ];
        default:
          throw new Error("Error: Invalid Form type")
      }
    })();




    return (
    <>
    <div className="space-y-4 divide-y divide-gray-200 h-full overflow-y-auto p-2">
    <div className="space-y-8 divide-y divide-gray-200">
      <div>
        <FormRender {...{handleChange, getValue, setValue, buttonTemplate, template}} />
      </div>
    </div>
  </div>
  
  </>
  )
}


export default ServiceTab;