/* This example requires Tailwind CSS v2.0+ */
import { Fragment } from 'react'
import { Disclosure, Menu, Transition } from '@headlessui/react'
import { BellIcon, MenuIcon, XIcon } from '@heroicons/react/outline'
import { Link, useNavigate } from 'react-router-dom'
import React from "react";
import { UserContext } from '../App';
import { useMutation } from '@apollo/client';
import { LOGOUT } from '../queries';
import { FOCUS_RING_COLOR, PRIMARY_BG_COLOR } from '../constants';

// const user = {
//   name: 'Tom Cook',
//   email: 'tom@example.com',
//   imageUrl:
//     'https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
// }
const navigation = [
  { name: 'Dashboard', href: '#', current: true },
  { name: 'Team', href: '#', current: false },
  { name: 'Projects', href: '#', current: false },
  { name: 'Calendar', href: '#', current: false },
]
const userNavigation = [
  { name: 'Your Profile', href: '/' },
  { name: 'Settings', href: '/settings' },
  // { name: 'Sign out', href: '#' },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}



export default function ApplicationShell({children, padding}) {
  const navigate = useNavigate();
  const context = React.useContext(UserContext);

  const loggedIn = context.user.loggedIn;
  const username = context.user.username;
  const imageUrl = context.user.imageUrl ?? undefined;
  const firstName = context.user.firstName;
  const lastName = context.user.lastName;

  let navigation = [];
  if (loggedIn){
    navigation = [
      { name: 'Your Profile', href: `/${username}` },
      { name: 'Settings', href: '/settings' },
    ]
  } else {
    navigation = [
      { name: 'Login', href: '/login' },
    ]
  }

  const [logout] = useMutation(LOGOUT, {
    onCompleted: (data) => {
      context.setUser({loggedIn: data.logout.loggedIn, username: undefined});
      navigate("/");
    }
  });

  const handleLogoutButton = () => {
    logout();
  };

  return (
    <>
      {/*
        This example requires updating your template:

        ```
        <html class="h-full">
        <body class="h-full">
        ```
      */}
      <div className="max-h-screen flex flex-col h-screen">
        <Disclosure as="nav" className="bg-white border-b border-gray-200">
          {({ open }) => (
            <>
              <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                <div className="flex justify-between h-16">
                  <div className="flex" >
                    <Link to="/" className="flex-shrink-0 flex items-center">
                    <div className="flex-shrink-0 flex items-center">
                      <img
                        className="block lg:hidden h-8 w-auto"
                        src="https://tailwindui.com/img/logos/workflow-mark-indigo-600.svg"
                        alt="Workflow"
                      />
                      <img
                        className="hidden lg:block h-8 w-auto"
                        src="https://tailwindui.com/img/logos/workflow-logo-indigo-600-mark-gray-800-text.svg"
                        alt="Workflow"
                      />
                    </div>
                    </Link>
                    <div className="hidden sm:-my-px sm:ml-6 sm:flex sm:space-x-8">
                      {/* {navigation.map((item) => (
                        <a
                          key={item.name}
                          href={item.href}
                          className={classNames(
                            item.current
                              ? 'border-indigo-500 text-gray-900'
                              : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                            'inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium'
                          )}
                          aria-current={item.current ? 'page' : undefined}
                        >
                          {item.name}
                        </a>
                      ))} */}
                    </div>
                  </div>
                  {
                  <div className="hidden sm:ml-6 sm:flex sm:items-center">
                    {/* <button
                      type="button"
                      className="bg-white p-1 rounded-full text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                      <span className="sr-only">View notifications</span>
                      <BellIcon className="h-6 w-6" aria-hidden="true" />
                    </button> */}

                    {/* Profile dropdown */}
                    { loggedIn && <Menu as="div" className="ml-3 relative">
                      <div>
                        <Menu.Button className={`max-w-xs bg-white flex items-center text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 ${FOCUS_RING_COLOR}`}>
                          <span className="sr-only">Open user menu</span>
                          <img className={`h-8 w-8 rounded-full ${PRIMARY_BG_COLOR}`} src={imageUrl} alt="" />
                        </Menu.Button>
                      </div>
                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-200"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                          {navigation.map((item) => (
                            <Menu.Item key={item.name}>
                              {({ active }) => (
                                <Link
                                //   href={item.href}
                                to={item.href}
                                  className={classNames(
                                    active ? 'bg-gray-100' : '',
                                    'block px-4 py-2 text-sm text-gray-700'
                                  )}
                                >
                                  {item.name}
                                </Link>
                              )}
                            </Menu.Item>  
                          ))}
                          { loggedIn && 
                            <Menu.Item>
                              <button  
                                onClick={handleLogoutButton}
                                className={classNames(
                                'block px-4 py-2 text-sm text-gray-700'
                              )}>
                                Sign Out
                              </button>
                            </Menu.Item>
                          }
                        </Menu.Items>
                      </Transition>
                    </Menu>
                    }

                    {
                      !loggedIn && <Link to={"/login"} className={classNames(
                        
                          'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                        'inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium'
                      )}>Login</Link>
                    }
                  </div>}
                  { <div className="-mr-2 flex items-center sm:hidden">
                    {/* Mobile menu button */}
                    <Disclosure.Button className={`bg-white inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 ${FOCUS_RING_COLOR}`}>
                      <span className="sr-only">Open main menu</span>
                      {open ? (
                        <XIcon className="block h-6 w-6" aria-hidden="true" />
                      ) : (
                        <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                      )}
                    </Disclosure.Button>
                  </div>}
                </div>
              </div>

              <Disclosure.Panel className="sm:hidden">
                <div className="pt-4 pb-3 border-t border-gray-200">
                  {loggedIn && <div className="flex items-center px-4">
                    <div className="flex-shrink-0">
                      <img className={`h-10 w-10 rounded-full ${PRIMARY_BG_COLOR}`} src={imageUrl} alt="" />
                    </div>
                    <div className="ml-3">
                      <div className="text-base font-medium text-gray-800">{firstName} {lastName}</div>
                      <div className="text-sm font-medium text-gray-500">@{username}</div>
                    </div>
                    {/* <button
                      type="button"
                      className="ml-auto bg-white flex-shrink-0 p-1 rounded-full text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                      <span className="sr-only">View notifications</span>
                      <BellIcon className="h-6 w-6" aria-hidden="true" />
                    </button> */}
                  </div>}
                  <div className="mt-3 space-y-1">
                    {navigation.map((item) => (
                      <Disclosure.Button
                        key={item.name}
                        as="a"
                        href={item.href}
                        className="block px-4 py-2 text-base font-medium text-gray-500 hover:text-gray-800 hover:bg-gray-100"
                      >
                        {item.name}
                      </Disclosure.Button>
                    ))}
                    {loggedIn && <button
                      onClick={handleLogoutButton}
                      className="block px-4 py-2 text-base font-medium text-gray-500 hover:text-gray-800 hover:bg-gray-100 text-left w-full"
                    >
                      Sign Out
                    </button>}

                  </div>
                </div>
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
        <div className={classNames(padding ? "py-10" : "", "overflow-y-hidden grow")}>
          {/* <header>
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
              <h1 className="text-3xl font-bold leading-tight text-gray-900">Dashboard</h1>
            </div>
          </header> */}
          <main className='h-full'>
            {/* <div className="max-w-7xl mx-auto sm:px-6 lg:px-8"> */}
            <div className={classNames(padding? "sm:px-6 lg:px-8" : "", "max-w-7xl mx-auto h-full")}> 
              {/* Replace with your content */}
              <div className={classNames(padding ? "px-4 py-8 sm:px-0" : "", 'h-full')}>
              {/* <div className="px-4 py-8 sm:px-0"> */}
                <div className='h-full md:px-0'>
                {/* <div className="border-4 border-dashed border-gray-200 rounded-lg h-96"> */}
                    {children}
                </div>
              </div>
              {/* /End replace */}
            </div>
          </main>
        </div>
      </div>
    </>
  )
}
