
export const stringToFloat = s => {
    return parseFloat(s);
}

export function convertRemToPixels(rem) {    
    return rem * parseFloat(getComputedStyle(document.documentElement).fontSize);
}

export function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}