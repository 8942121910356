import { useMutation, useQuery } from "@apollo/client";
import { XCircleIcon } from "@heroicons/react/outline";
import { XIcon } from "@heroicons/react/solid";
import React from "react";
import { FOCUS_RING_COLOR, RING_COLOR } from "../../constants";
import { GET_SETTINGS, UPDATE_SELECTED_ITEMS } from "../../queries";
import { Input } from "../Inputs";
import Notification from "../Notification";
import { TwoPanel } from "../Pannels";

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }


function updateListObjects(id, attr, val, list, setter) {
    const index = list.findIndex(ele => ele.id === id);
        if (index > -1){
            let new_obj = {...(list[index])};
            new_obj[attr] = val;
            setter([
                ...(list.slice(0, index)),
                new_obj,
                ...(list.slice(index + 1, list.length))
            ]);
        }
}

const ClickableCross = ({children, onClick, className}) => (
    <div className={classNames("relative", className)}>
        <div className="absolute -left-1 -top-1 w-3 h-3 bg-white rounded border border-black flex items-center justify-center "
            onClick={onClick}
        >
            <XIcon className="w-2 h-2 " />
        </div>
        {children}
    </div>
)

const socials = [
    {
      name: 'Facebook',
      href: '#',
      icon: (props) => (
        <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
          <path
            fillRule="evenodd"
            d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
            clipRule="evenodd"
          />
        </svg>
      ),
    },
    {
      name: 'Instagram',
      href: '#',
      icon: (props) => (
        <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
          <path
            fillRule="evenodd"
            d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
            clipRule="evenodd"
          />
        </svg>
      ),
    },
    {
      name: 'Twitter',
      href: '#',
      icon: (props) => (
        <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
          <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
        </svg>
      ),
    },
    {
        name: "Tiktok",
        href: "#",
        icon: (props) => (
          <svg  viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path d="M16.708 0.027c1.745-0.027 3.48-0.011 5.213-0.027 0.105 2.041 0.839 4.12 2.333 5.563 1.491 1.479 3.6 2.156 5.652 2.385v5.369c-1.923-0.063-3.855-0.463-5.6-1.291-0.76-0.344-1.468-0.787-2.161-1.24-0.009 3.896 0.016 7.787-0.025 11.667-0.104 1.864-0.719 3.719-1.803 5.255-1.744 2.557-4.771 4.224-7.88 4.276-1.907 0.109-3.812-0.411-5.437-1.369-2.693-1.588-4.588-4.495-4.864-7.615-0.032-0.667-0.043-1.333-0.016-1.984 0.24-2.537 1.495-4.964 3.443-6.615 2.208-1.923 5.301-2.839 8.197-2.297 0.027 1.975-0.052 3.948-0.052 5.923-1.323-0.428-2.869-0.308-4.025 0.495-0.844 0.547-1.485 1.385-1.819 2.333-0.276 0.676-0.197 1.427-0.181 2.145 0.317 2.188 2.421 4.027 4.667 3.828 1.489-0.016 2.916-0.88 3.692-2.145 0.251-0.443 0.532-0.896 0.547-1.417 0.131-2.385 0.079-4.76 0.095-7.145 0.011-5.375-0.016-10.735 0.025-16.093z"/>
          </svg>
        )
      }
]







const ProfileTab = () => {
    const [media, setMedia] = React.useState([]);
    const [metrics, setMetrics] = React.useState([]);
    const [constants, setConstants] = React.useState({MAX_SELECTED_MEDIA: 0, MAX_SELECTED_METRICS: 0})
    const [error, setError] = React.useState(null);

    const [notificationButton, setNotificationButton] = React.useState("ERROR");
    const [showNotification, setShowNotification] = React.useState(false);

    const [updateServer] = useMutation(UPDATE_SELECTED_ITEMS);
 
    const updateMedia = (id, attr, val) => updateListObjects(id, attr, val, media, setMedia);
    const updateMetrics = (id, attr, val) => updateListObjects(id, attr, val, metrics, setMetrics);


    useQuery(GET_SETTINGS, {variables: {userId: 1},
        onCompleted: (data) => {
            setMedia(data.settings.media);
            setMetrics(data.settings.metrics);
            setConstants(data.constants);
        }
    });

    const validSelection = (
        media.filter(m=>m.selected).length <= constants.MAX_SELECTED_MEDIA &&
        metrics.filter(m=>m.selected).length <= constants.MAX_SELECTED_METRICS
    )


    const handleSaveButton = () => {
        if (validSelection){
            const variables = {
                media:  media.map(({selected, id}) =>  ({id, selected})),
                metrics: metrics.map(({selected, id}) => ({id, selected}))
            }
            // const settings = {
            //     user: {},
            //     profile: {},
            //     media: media.map(({selected, id}) =>  ({id, selected})),
            //     metrics: metrics.map(({selected, id}) => ({id, selected}))
            // }

            updateServer({variables, 
                onCompleted: () =>{
                    setNotificationButton("SUCCESS");
                    setShowNotification(true);
                },
                onError: () => {
                    setNotificationButton("ERROR");
                    setShowNotification(true);
                }
            })
        } else {
            setError("You have selected too many metrics or media");
        }
    }

    return (
            <>
            <div className="flex-1 xl:overflow-y-auto">
                  <div className="max-w-3xl mx-auto py-10 px-4 sm:px-6 lg:py-12 lg:px-8">
                  <h1 className="text-3xl font-extrabold text-blue-gray-900">Profile</h1>
      
                  <div className="mt-6 space-y-8 divide-y divide-y-blue-gray-200">
                        
            
                        <div className="grid grid-cols-1 gap-y-6 sm:grid-cols-6 sm:gap-x-6">
                            <div className="sm:col-span-6 my-2">
                                <h2 className="text-xl font-medium text-blue-gray-900">Gallery</h2>
                                <p className="mt-1 text-sm text-blue-gray-500">
                                Share photos that highlight your profile.
                                </p>
                            </div>
                            
                            {/* Selected Panels */}

                            <TwoPanel 
                            leftTitle={"Selected Media"}
                            leftChild={
                                media.filter(ele=>ele.selected).map(({url, id}, index) => (
                                    <ClickableCross key={id} onClick={()=>updateMedia(id, "selected", false)}>
                                        <div  
                                        className="w-16 h-16 rounded bg-center bg-contain"
                                        style={{backgroundImage: `url(${url})`}}/>
                                    </ClickableCross>
                                    // <div key={id} className="relative">
                                    //     <div className="absolute -left-1 -top-1 w-3 h-3 bg-white rounded border border-black flex items-center justify-center "
                                    //         onClick={()=>updateMedia(id, "selected", false)}
                                    //     >
                                    //         <XIcon className="w-2 h-2 " />
                                    //     </div>
                                    //     <div  
                                    //     className="w-10 h-10 rounded bg-center bg-contain"
                                    //     style={{backgroundImage: `url(${url})`}}/>
                                    // </div>
                                ))} 

                                
                            rightTitle={"Media Library"}
                            rightChild={
                                media.map(({url, id, selected}, index) => (
                                    <div key={id} 
                                    className={classNames(selected? `ring-2 ${RING_COLOR}`: "", "w-16 h-16 rounded bg-center bg-contain")} 
                                    onClick={()=>updateMedia(id, "selected", !selected)}
                                    style={{backgroundImage: `url(${url})`}}/>
                                ))}
                                />
                            
                        </div>
            
                        <div className="grid grid-cols-1 gap-y-6 sm:grid-cols-6 sm:gap-x-6">
                            <div className="sm:col-span-6 my-2">
                                <h2 className="text-xl font-medium text-blue-gray-900">Notables</h2>
                                <p className="mt-1 text-sm text-blue-gray-500">
                                Share insights that highlight your profile.
                                </p>
                            </div>
                            
                            <TwoPanel 
                            leftTitle={"Metric Library"}
                            leftChild={
                                metrics.map(({quantifier, qualifier, id, selected}) => (
                                    <div key={id} 
                                    className={classNames( 
                                        selected? "bg-green-100": "", 
                                        "w-full h-10  flex px-2 items-center justify-between")}
                                    onClick={()=>updateMetrics(id, "selected", !selected)}
                                    >
                                        <div className="text-sm">{qualifier}</div>
                                        <div className="text-sm">{quantifier}</div>
                                        
                                    </div>
                                ))} 

                                
                            rightTitle={"Selected Metrics"}
                            rightChild={
                                metrics.filter(metric=>metric.selected).map(({id, quantifier, qualifier}, index) => (
                                    <ClickableCross key={id} onClick={()=>updateMetrics(id, "selected", false)} className="w-full">
                                        <div key={id} className="col-span-1 px-1 py-2 rounded border w-full flex justify-between items-center">
                                            <dt className="text-sm font-medium text-gray-500 truncate col-span-2">{qualifier}</dt>
                                            <dd className="mt-1 text-3xl font-semibold text-gray-900 col-span-1">{quantifier}</dd>
                                        </div>
                                    </ClickableCross>
                                ))
                                }
                            />
                            {/* <div className="w-full h-64  col-span-full  border rounded grid-cols-1 md:grid-cols-2">
                                <div className="md:col-start-1 h-full col-span-full md:col-span-1  border-r  px-2">
                                    <div className="border-b w-full h-10 p-2" >Search</div>
                                    
                                    
                                    <div className="grow overflow-y-auto divide-y">
                                    {
                                        metrics.map(({quantifier, qualifier, id, selected}) => (
                                            <div key={id} 
                                            className={classNames( 
                                                selected? "bg-green-100": "", 
                                                "w-full h-10  flex px-2 items-center justify-between")}
                                            onClick={()=>updateMetrics(id, "selected", !selected)}
                                            >
                                                <div className="text-sm">{qualifier}</div>
                                                <div className="text-sm">{quantifier}</div>
                                                
                                            </div>
                                        ))
                                    }
                                    </div>
                                </div>
                
                                <div className="md:col-start-2 h-full col-span-full md:col-span-1  px-2  border-b md:border-none">
                                    
                                    <h3 className="text-sm  py-1 mb-1">Selected</h3>
                                    <dl className="grid grid-cols-2 gap-2 py-2 content-start flex-1 overflow-y-auto">
                                    {
                                        metrics.filter(metric=>metric.selected).map(({id, quantifier, qualifier}, index) => (
                                            <div key={id} className="col-span-1 px-1 py-2 rounded border ">
                                                <dt className="text-sm font-medium text-gray-500 truncate">{qualifier}</dt>
                                                <dd className="mt-1 text-3xl font-semibold text-gray-900">{quantifier}</dd>
                                               
                                            </div>
                                        ))
                                    }
                                    </dl>
                                </div>
                            </div> */}
                        </div>

                        

                       
                     
                        
                        <div className="pt-2 ">
                            {<p className={classNames("text-red-500 text-right pb-2", (error === null) || (error !== null && validSelection) ? "invisible": "")}>{error}</p>}
                            <div className="flex justify-end">
                                {/* <button
                                    type="button"
                                    className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-blue-gray-900 hover:bg-blue-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                                >
                                    Cancel
                                </button> */}
                                <button
                                    className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                                    onClick={handleSaveButton}
                                >
                                    Save
                                </button>
                            </div>
                        </div>
                  </div>
      
      
                  
      
      
      
                  
      
      
                  </div>
              </div>
              {<Notification show={showNotification} setShow={setShowNotification} alertType={notificationButton}/>}
              </>
          )
    
}

export default ProfileTab;