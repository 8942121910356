/*
  This example requires Tailwind CSS v2.0+ 
  
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  const colors = require('tailwindcss/colors')
  
  module.exports = {
    // ...
    theme: {
      extend: {
        colors: {
          'blue-gray': colors.blueGray,
        },
      },
    },
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/

import { Fragment, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import {
  BellIcon,
  BookmarkAltIcon,
  CashIcon,
  CogIcon,
  FireIcon,
  HomeIcon,
  InboxIcon,
  KeyIcon,
  UserCircleIcon,
  PhotographIcon,
  SearchCircleIcon,
  UserIcon,
  ViewGridAddIcon,
  XIcon,
  ShoppingBagIcon,
  PuzzleIcon,
  ShieldCheckIcon,
} from '@heroicons/react/outline'
import { ChevronLeftIcon } from '@heroicons/react/solid'
import React from "react";
import AccountTab from './SettingsTabs/AccountTab';
import ProfileTab from './SettingsTabs/ProfileTab'
import ServiceTab from './SettingsTabs/ServiceTab';
import IntegrationsTab from './SettingsTabs/IntegrationsTab';
import { PrivacyTab } from './SettingsTabs/PrivacyTab';
const navigation = [
  { name: 'Home', href: '#', icon: HomeIcon },
  { name: 'Trending', href: '#', icon: FireIcon },
  { name: 'Bookmarks', href: '#', icon: BookmarkAltIcon },
  { name: 'Messages', href: '#', icon: InboxIcon },
  { name: 'Profile', href: '#', icon: UserIcon },
]
const subNavigation = [
  {
    name: 'Account',
    description: 'Ullamcorper id at suspendisse nec id volutpat vestibulum enim. Interdum blandit.',
    href: '#',
    icon: CogIcon,
    // current: true,
    component: AccountTab
  },
  {
    name: 'Profile',
    description: 'Enim, nullam mi vel et libero urna lectus enim. Et sed in maecenas tellus.',
    href: '#',
    icon: UserCircleIcon,
    // current: false,
    component: ProfileTab
  },
  {
    name: 'Services',
    description: 'Semper accumsan massa vel volutpat massa. Non turpis ut nulla aliquet turpis.',
    href: '#',
    icon: ShoppingBagIcon,
    // current: false,
    component: ServiceTab
  },
  {
    name: "Integrations",
    description: 'Semper accumsan massa vel volutpat massa. Non turpis ut nulla aliquet turpis.',
    href: "#",
    icon: PuzzleIcon,
    component: IntegrationsTab
  },
  {
    name: "Security",
    description: 'Semper accumsan massa vel volutpat massa. Non turpis ut nulla aliquet turpis.',
    href: "#",
    icon: ShieldCheckIcon,
    component: PrivacyTab
  }
  // {
  //   name: 'Appearance',
  //   description: 'Magna nulla id sed ornare ipsum eget. Massa eget porttitor suscipit consequat.',
  //   href: '#',
  //   icon: PhotographIcon,
  //   current: false,
  // },
  // {
  //   name: 'Billing',
  //   description: 'Orci aliquam arcu egestas turpis cursus. Lectus faucibus netus dui auctor mauris.',
  //   href: '#',
  //   icon: CashIcon,
  //   current: false,
  // },
  // {
  //   name: 'Integrations',
  //   description: 'Nisi, elit volutpat odio urna quis arcu faucibus dui. Mauris adipiscing pellentesque.',
  //   href: '#',
  //   icon: ViewGridAddIcon,
  //   current: false,
  // },
  // {
  //   name: 'Additional Resources',
  //   description: 'Quis viverra netus donec ut auctor fringilla facilisis. Nunc sit donec cursus sit quis et.',
  //   href: '#',
  //   icon: SearchCircleIcon,
  //   current: false,
  // },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}



export default function SettingsTemplate({children}) {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [currentTab, setCurrentTab] = useState(null);


  // const component = subNavigation.find(nav => nav.name === currentTab).component();
  const tab = subNavigation.find(nav=>nav.name === currentTab);
  
  let focus_component = <EmptyPane setCurrentTab={setCurrentTab} />
  if (tab) {
    focus_component = <tab.component />
  }

  return (
    <>
      {/*
        This example requires updating your template:

        ```
        <html class="h-full bg-blue-gray-50">
        <body class="h-full overflow-hidden">
        ```
      */}
      <div className="h-full flex">
        <Transition.Root show={mobileMenuOpen} as={Fragment}>
          <Dialog as="div" className="fixed inset-0 flex z-40 lg:hidden" onClose={setMobileMenuOpen}>
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-blue-gray-600 bg-opacity-75" />
            </Transition.Child>
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <div className="relative flex-1 flex flex-col max-w-xs w-full bg-white focus:outline-none">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute top-0 right-0 -mr-12 pt-4">
                    <button
                      type="button"
                      className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                      onClick={() => setMobileMenuOpen(false)}
                    >
                      <span className="sr-only">Close sidebar</span>
                      <XIcon className="h-6 w-6 text-white" aria-hidden="true" />
                    </button>
                  </div>
                </Transition.Child>
                <div className="pt-5 pb-4">
                  <div className="flex-shrink-0 flex items-center px-4">
                    <img
                      className="h-8 w-auto"
                      src="https://tailwindui.com/img/logos/workflow-mark.svg?color=blue&shade=600"
                      alt="Workflow"
                    />
                  </div>
                  <nav aria-label="Sidebar" className="mt-5">
                    <div className="px-2 space-y-1">
                      {navigation.map((item) => (
                        <a
                          key={item.name}
                          href={item.href}
                          className="group p-2 rounded-md flex items-center text-base font-medium text-blue-gray-600 hover:bg-blue-gray-50 hover:text-blue-gray-900"
                        >
                          <item.icon
                            className="mr-4 h-6 w-6 text-blue-gray-400 group-hover:text-blue-gray-500"
                            aria-hidden="true"
                          />
                          {item.name}
                        </a>
                      ))}
                    </div>
                  </nav>
                </div>
                <div className="flex-shrink-0 flex border-t border-blue-gray-200 p-4">
                  <a href="#" className="flex-shrink-0 group block">
                    <div className="flex items-center">
                      <div>
                        <img
                          className="inline-block h-10 w-10 rounded-full"
                          src="https://images.unsplash.com/photo-1550525811-e5869dd03032?ixlib=rb-1.2.1&auto=format&fit=facearea&facepad=2.5&w=256&h=256&q=80"
                          alt=""
                        />
                      </div>
                      <div className="ml-3">
                        <p className="text-base font-medium text-blue-gray-700 group-hover:text-blue-gray-900">
                          Lisa Marie
                        </p>
                        <p className="text-sm font-medium text-blue-gray-500 group-hover:text-blue-gray-700">
                          Account Settings
                        </p>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            </Transition.Child>
            <div className="flex-shrink-0 w-14" aria-hidden="true">
              {/* Force sidebar to shrink to fit close icon */}
            </div>
          </Dialog>
        </Transition.Root>
        <div className="flex-1 min-w-0 flex flex-col overflow-hidden">

          <main className="flex-1 flex overflow-hidden">
            <div className="flex-1 flex flex-col overflow-y-auto xl:overflow-hidden">
              {/* Breadcrumb */}
             { (currentTab !== null) &&  
              <nav aria-label="Breadcrumb" className="bg-white border-b border-blue-gray-200 xl:hidden">
                  <div className="max-w-3xl mx-auto py-3 px-4 flex items-start sm:px-6 lg:px-8">
                    <button
                      onClick={()=>setCurrentTab(null)}
                      className="-ml-1 inline-flex items-center space-x-3 text-sm font-medium text-blue-gray-900"
                    >
                      <ChevronLeftIcon className="h-5 w-5 text-blue-gray-400" aria-hidden="true" />
                      <span>Settings</span>
                    </button>
                  </div>
                </nav>
              }

              <div className="flex-1 flex xl:overflow-hidden">
                {/* Secondary sidebar */}
                <nav
                  aria-label="Sections"
                  className="hidden flex-shrink-0 w-96 bg-white border-r border-blue-gray-200 xl:flex xl:flex-col"
                >
                  <div className="flex-shrink-0 h-16 px-6 border-b border-blue-gray-200 flex items-center">
                    <p className="text-lg font-medium text-blue-gray-900">Settings</p>
                  </div>
                  <div className="flex-1 min-h-0 overflow-y-auto">
                    {subNavigation.map((item) => (
                      <button
                        key={item.name}
                        href={item.href}
                        className={classNames(
                          item.name === currentTab ? 'bg-blue-50 bg-opacity-50' : 'hover:bg-blue-50 hover:bg-opacity-50',
                          'flex p-6 border-b border-blue-gray-200 text-left'
                        )}
                        onClick={()=>setCurrentTab(item.name)}
                        aria-current={item.current ? 'page' : undefined}
                      >
                        <item.icon className="flex-shrink-0 -mt-0.5 h-6 w-6 text-blue-gray-400" aria-hidden="true" />
                        <div className="ml-3 text-sm">
                          <p className="font-medium text-blue-gray-900">{item.name}</p>
                          <p className="mt-1 text-blue-gray-500">{item.description}</p>
                        </div>
                      </button>
                    ))}
                  </div>
                </nav>

                {/* Main content */}
                {focus_component}
                {/* {<tab.component/>} */}
                {/* {component} */}
              </div>
            </div>
          </main>
        </div>
      </div>
    </>
  )
}

const EmptyPane = ({setCurrentTab}) => {


  return (
    <>
    <div className="flex-1 xl:overflow-y-auto xl:hidden">
      <div className="max-w-3xl mx-auto py-10 px-4 sm:px-6 lg:py-12 lg:px-8">
      <h1 className="text-3xl font-extrabold text-blue-gray-900 mb-3">Settings</h1>

        {
          subNavigation.map((item, index) => {
            // const {name, description} = item;
            return (
              <button key={index} 
                className="flex mb-3 hover:underline"
                onClick={()=>setCurrentTab(item.name)}
              >

                <item.icon className="flex-shrink-0 -mt-0.5 h-6 w-6 text-blue-gray-400" aria-hidden="true" />
                <div className="ml-3 text-sm text-left ">
                  <p className="font-medium text-blue-gray-900">{item.name}</p>
                  <p className="mt-1 text-blue-gray-500">{item.description}</p>
                </div>
              </button>
              
            )
          })
        }
      </div>
    </div>


    <div className="hidden flex-1 xl:overflow-y-auto xl:flex justify-center mt-44">
      <div className='text-center'>
        <h1 className="text-xl font-extrabold text-blue-gray-900 mb-1">Select Settings</h1>
        <p>Select panel on the left to update settings</p>
      </div>
        

    </div>
    </>

  )
}
