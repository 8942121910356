
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import React from "react";
import { SocialComponents } from "../../constants";
import { GET_SETTINGS, UPDATE_SETTINGS, USERNAME_EXISTS } from "../../queries";
import { useCheckExistingUsername } from "../../QueryHooks";
import { Button } from "../Buttons";
import { Input, InputAddOn, Textarea, nonEmptyEntry, emptyEntry, validHttpUrl, autoFormatHttpUrl, validateEmail } from "../Inputs";
import Notification from "../Notification";
import { useArrayObjectState, useObjectState } from "../StateHooks";
import { FormRender, useCheckValid } from "./FormRender";


function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }

const MAX_BIO_COUNT = 300;
const MAX_FIRST_NAME_COUNT = 20;
const MAX_LAST_NAME_COUNT = 20;
const MAX_USERNAME_COUNT = 20;
const MAX_ALIAS_COUNT = 30;

const nonEmptyError = {func: nonEmptyEntry, message: "Field must not be empty"};
const lengthError = (count) => ({func: str=>str.length <= count, message: "Field is too long"});
// Each element in the list is a section (separated by divider)
const useTemplate = (fields, initialUsername) => {
    const usernameExists = useCheckExistingUsername(fields.username);
    return [
    {
        sectionTitle: "Profile",
        sectionDescription: "This information will be displayed publicly so be careful what you share",
        inputs: [
            {
                component: Input,
                name: "firstName",
                props: {
                    className: "sm:col-span-3",
                    header: "First name",
                    maxChar: MAX_FIRST_NAME_COUNT,
                    charCountAppear: fields.firstName.length >= MAX_FIRST_NAME_COUNT - 10
                },
                errors: [
                    nonEmptyError,
                    lengthError(MAX_FIRST_NAME_COUNT)
                ]
            },
            {
                component: Input,
                name: "lastName",
                props: {
                    className: "sm:col-span-3",
                    header: "Last Name",
                    maxChar: MAX_LAST_NAME_COUNT,
                    charCountAppear: fields.lastName.length >= MAX_LAST_NAME_COUNT - 10
                },
                errors: [
                    nonEmptyError,
                    lengthError(MAX_LAST_NAME_COUNT)
                ]
            },
            {
                component: InputAddOn,
                name: "username",
                props: {
                    className: "sm:col-span-6",
                    header: "Username",
                    addOnValue: "mediapacquet.con/",
                    maxChar: MAX_USERNAME_COUNT,
                    charCountAppear: fields.username.length >= MAX_USERNAME_COUNT - 10
                },
                errors: [
                    nonEmptyError,
                    lengthError(MAX_USERNAME_COUNT),
                    {func: str => !usernameExists || str === initialUsername, message: "The username you entered already exists!"}
                ]
            },
            {
                component: InputAddOn,
                name: "alias",
                props: {
                    className: "sm:col-span-6",
                    header: "Alias",
                    addOnValue: "I am a",
                    maxChar: MAX_ALIAS_COUNT,
                    charCountAppear: fields.alias.length >= MAX_ALIAS_COUNT - 10
                },
                errors: [
                    nonEmptyError,
                    lengthError(MAX_ALIAS_COUNT)
                ]
            },
            {
                component: Textarea,
                name: "biography",
                props: {
                    className: "sm:col-span-6",
                    header: "About Me",
                    rows: 4,
                    description: "Brief description for your profile.",
                    maxChar: MAX_BIO_COUNT,
                    charCountAppear: true
                },
                errors: [
                    nonEmptyError,
                    lengthError(MAX_BIO_COUNT)
                ]
            }
        ]
    },

    {
        sectionTitle: "Social Media Links",
        sectionDescription: "Share links to your social media accounts.",
        inputs: Object.values(SocialComponents).map(platform=>({
            component: SocialInput,
            name: platform.name,
            props: {
                platform,
            },
            updateOnBlur: autoFormatHttpUrl,
            errors: [

            ]
        }))
    },

    {
        sectionTitle: "Personal Information",
        sectionDescription: "This information is kept private.",
        inputs: [
            {
                component: Input,
                name: "email",
                props: {
                    className: "sm:col-span-3",
                    header: "Email Address"
                }, 
                errors: [
                    nonEmptyError,
                    {func: validateEmail, message: "You must provide a valid email."}
                ]
            }
        ]
    }

]}


const SocialInput = ({platform, ...props}) => (
    <div key={platform.name} className="w-full col-span-full  py-2  grid grid-cols-6 gap-5 ">
        <div className="flex gap-2  col-span-full md:col-span-1 items-center">
            <platform.icon className="w-6 h-6" />
            <div>{platform.name}</div>
        </div>
        <Input className={"col-span-full grow md:col-span-5"} {...props}/>
    </div>
)

const Section = ({children, title, description}) => (
    <div className="grid grid-cols-1 gap-y-6 sm:grid-cols-6 sm:gap-x-6">
        <div className="sm:col-span-6 my-2">
            <h2 className="text-xl font-medium text-blue-gray-900">{title}</h2>
            <p className="mt-1 text-sm text-blue-gray-500">{description}</p>
        </div>
        
        {children}
       
    </div>
)



  
const AccountTab = () => {

    const [fields, setFields, fieldsChange, setFieldValue] = useObjectState({
        firstName: "",
        lastName: "",
        username: "",
        alias: "",
        biography: "",
        email: ""
    });

    const [platforms, setPlatforms, platformChange, getPlatformAttribute, setPlatformAttribute] = useArrayObjectState([]);

    const [initialUsername, setInitialUsername] = React.useState("");
    const [notificationButton, setNotificationButton] = React.useState("ERROR");
    const [showNotification, setShowNotification] = React.useState(false);

    
    const existingUsername = useCheckExistingUsername(fields.username)

    const [updateSettings] = useMutation(UPDATE_SETTINGS, {refetchQueries: [GET_SETTINGS]})
    
    const handleChange = (attr) => {
        if (attr in fields){
            return fieldsChange(attr);
        } else {
            return platformChange("userLink", "name", attr);
        }
    }

    const getValue = (attr) => {
        if (attr in fields){
            return fields[attr];
        } else {
            return getPlatformAttribute("userLink", "name", attr);
        }
    }

    const setValue = (attr, value) => {
        if (attr in fields){
            setFieldValue(attr, value)
        } else {
            setPlatformAttribute("userLink", value, "name", attr)
        }
    }

    const handleSaveButton = () => {
        const {lastName, firstName, username, biography, alias, email} = fields;
        const settings = {
            user: {lastName, firstName, username, email},
            profile: {bio: biography, influencerType: alias},
            media: [],
            metrics: [],
            platforms: platforms.map(({name, userLink})=> ({name, userLink}))
        }

        updateSettings({variables: { settings},
            onCompleted: () =>{
                setNotificationButton("SUCCESS");
                setShowNotification(true);
            },
            onError: (err) => {
                setNotificationButton("ERROR");
                setShowNotification(true);
            }
        }) 
    }

    // Load the user's settings and place them appropriately in the fields
    useQuery(GET_SETTINGS, {
        onCompleted: data => {
            const {lastName, firstName, username, email} = data.settings.user;
            const {bio, influencerType} = data.settings.profile;
            const {platforms} = data.settings;

            const newField = {...fields, firstName, lastName, username, email, alias: influencerType, biography: bio};
            setFields(newField);
            setPlatforms(platforms);
            setInitialUsername(username)
        }
    });

    const buttonTemplate = React.useMemo(()=>([
        {
            component: Button,
            type: "SUBMIT",
            props: {
                type: "PRIMARY",
                children: "Save",
                onClick: handleSaveButton
            }
        }  
    ]));
  
    return (
      <>
      <div className="flex-1 xl:overflow-y-auto">
            <div className="max-w-3xl mx-auto py-10 px-4 sm:px-6 lg:py-12 lg:px-8">
            <h1 className="text-3xl font-extrabold text-blue-gray-900">Account</h1>
            <FormRender {...{handleChange, getValue, setValue, buttonTemplate, template: useTemplate(fields, initialUsername)}} /> 

            </div>
        </div>

        {<Notification show={showNotification} setShow={setShowNotification} alertType={notificationButton}/>}
        </>
    )
  }
  
  
  export default AccountTab;


  /* Insert photo */
/* <div className="sm:col-span-6">
    <label htmlFor="photo" className="block text-sm font-medium text-blue-gray-900">
    Photo
    </label>
    <div className="mt-1 flex items-center">
    <img
        className="inline-block h-12 w-12 rounded-full"
        src="https://images.unsplash.com/photo-1550525811-e5869dd03032?ixlib=rb-1.2.1&auto=format&fit=facearea&facepad=2.5&w=256&h=256&q=80"
        alt=""
    />
    <div className="ml-4 flex">
        <div className="relative bg-white py-2 px-3 border border-blue-gray-300 rounded-md shadow-sm flex items-center cursor-pointer hover:bg-blue-gray-50 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-blue-gray-50 focus-within:ring-blue-500">
        <label
            htmlFor="user-photo"
            className="relative text-sm font-medium text-blue-gray-900 pointer-events-none"
        >
            <span>Change</span>
            <span className="sr-only"> user photo</span>
        </label>
        <input
            id="user-photo"
            name="user-photo"
            type="file"
            className="absolute inset-0 w-full h-full opacity-0 cursor-pointer border-gray-300 rounded-md"
        />
        </div>
        <button
        type="button"
        className="ml-3 bg-transparent py-2 px-3 border border-transparent rounded-md text-sm font-medium text-blue-gray-900 hover:text-blue-gray-700 focus:outline-none focus:border-blue-gray-300 focus:ring-2 focus:ring-offset-2 focus:ring-offset-blue-gray-50 focus:ring-blue-500"
        >
        Remove
        </button>
    </div>
    </div>
</div> */



/* <div className="mt-6 space-y-8 divide-y divide-y-blue-gray-200">
            {
                template(fields).map(({sectionTitle, sectionDescription, inputs}) => (
                    <Section key={sectionTitle} title={sectionTitle} description={sectionDescription}>
                        {
                            inputs.map((input, index) => {
                                // See if any errors exist
                                const value = getValue(input.name)
                                const errors = input.errors.filter(({func}) => !func(fields[input.name]));
                                const errorMessages = errors.map(err=>err.message)
                                const valid = errors.length === 0;
                                const displayValid = submitClicked ? valid: null;
                                const onBlur = input.updateOnBlur? () => setValue(input.name, input.updateOnBlur(value)): undefined;
                                return(
                                    <input.component 
                                        key={input.name} 
                                        valid={displayValid} 
                                        value={value} 
                                        invalidMessage={errorMessages}
                                        onChange={handleChange(input.name)}
                                        onBlur={onBlur}
                                        {...(input.props)} 
                                    />
                                )
                                })
                        }
                    </Section>
                ))
            }
            <div className="pt-8 flex justify-end">
                {
                    buttonTemplate.map(button => (
                        <button.component {...(button.props)} />
                    ))
                }
                
            </div>
            </div> */
        