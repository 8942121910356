/*
  This example requires Tailwind CSS v2.0+ 
  
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/
import { useMutation } from '@apollo/client';
import { LockClosedIcon } from '@heroicons/react/solid';
import React from "react";
import { Link, Navigate, useNavigate } from 'react-router-dom';
import { UserContext } from '../App';
import { FOCUS_RING_COLOR, HOVER_PRIMARY_TEXT_COLOR, PRIMARY_TEXT_COLOR } from '../constants';
import { LOGIN, SIGNUP } from '../queries';
import { useCheckExistingUsername } from '../QueryHooks';
import { classNames } from '../uitils';
import { Button } from './Buttons';
import { Input, nonEmptyEntry } from './Inputs';
import Notification from './Notification';
import { FormRender, nonEmptyError } from './SettingsTabs/FormRender';
import { useObjectState } from './StateHooks';

export const loginTypes = {
    LOGIN: "login",
    REGISTER: "register"
}
const RememberMe = ({className}) => (
  <div className={classNames("flex items-center justify-between pt-2", className)}>
      <div className="flex items-center">
          <input
          id="remember-me"
          name="remember-me"
          type="checkbox"
          className={`h-4 w-4 ${PRIMARY_TEXT_COLOR} ${FOCUS_RING_COLOR} border-gray-300 rounded`}
          />
          <label htmlFor="remember-me" className="ml-2 block text-sm text-gray-900">
          Remember me
          </label>
      </div>

      <div className="text-sm">
          <a href="#" className={`font-medium ${PRIMARY_TEXT_COLOR} ${HOVER_PRIMARY_TEXT_COLOR}`}>
          Forgot your password?
          </a>
      </div>
  </div>
)
const template = (loginState) => {
  const personalInfo = [{
    inputs : [
      {
        component: Input,
        name: "firstName",
        props: {
          placeholder: "First Name",
          className: "sm:col-span-3"
        },
        errors: [
          nonEmptyError
        ]
      },
      {
        component: Input,
        name: "lastName", 
        props: {
          placeholder: "Last Name",
          className: "sm:col-span-3"
        },
        errors: [
          nonEmptyError
        ]

      },
      {
        component: Input,
        name: "email",
        props: {
          placeholder: "Email",
          className: "sm:col-span-6"
        },
        errors: [
          nonEmptyError
        ]
      },
      {
        component: Input,
        name: "authorizationCode",
        props: {
          placeholder: "Authorization Code",
          className: "sm:col-span-6"
        }, 
        errors: [
          nonEmptyError
        ]
      }
  
    ]
  }];

  const loginInfo = [{
    inputs: [
      {
        component: Input,
        name: "username",
        props: {
          placeholder: "Username",
          className: "sm:col-span-6"
        },
        errors: [
          nonEmptyError
        ],
      },
      {
        component: Input,
        name: "password",
        props: {
          placeholder: "Password",
          className: "sm:col-span-6",
          type: "password"
        },

        errors: [
          nonEmptyError
        ]
      },
      {
        component: RememberMe,
        props: {
          className: classNames(loginState == loginTypes.REGISTER? "hidden": "", "sm:col-span-6")
        }
      }
    ]
  }];

  if (loginState === loginTypes.LOGIN) return loginInfo;
  return personalInfo.concat(loginInfo);

}

export default function Login({loginState}) {
    // const [loginState, setLoginState] = React.useState(loginTypes.LOGIN);
    const [showNotification, setShowNotification] = React.useState(false);
    const [notificationProps, setNotificationProps] = React.useState({alertType: "ERROR"})

    const navigate = useNavigate();
    const {user, setUser} = React.useContext(UserContext);

    const [input, setInput, handleChange, _] = useObjectState({
      firstName: "",
      lastName: "",
      email: "",
      username: "",
      password: "",
      authorizationCode: ""
    })

    const [login] = useMutation(LOGIN, {
      variables: {username: input.username, password: input.password},
      onCompleted: ({login}) => {
          // localStorage.setItem(AUTH_TOKEN, login.token);
          setUser({...user, loggedIn: login.loggedIn, username: login.username})
          navigate(`/${login.username}`)
      },
      onError: (err) => {
        setNotificationProps({alertType: "ERROR", message: err.message})
        setShowNotification(true);
      }
    });

    const [signup] = useMutation(SIGNUP, {
        variables: {
            signupInput: {
                email: input.email,
                password: input.password,
                firstName: input.firstName,
                lastName: input.lastName,
                username: input.username,
                age: 18,
                authorizationCode: input.authorizationCode
            }
        },
        onCompleted: () => {
            navigate(`/${input.username}`);
        },
        onError: (err) => {
            setNotificationProps({alertType: "ERROR", message: err.message});
            setShowNotification(true);
        }
    })

    const usernameExists = useCheckExistingUsername(input.username);

    
    // const handleChanges = handleChange; 
    const getValue = attr => input[attr];
    const setValue = setInput;

    const onClick = loginState === loginTypes.LOGIN ? login: signup;
    const buttonTemplate  = [
      {
        component: Button,
        type: "SUBMIT",
        props: {
          type: "PRIMARY",
          children: loginState === loginTypes.LOGIN ? "Sign in" : "Sign up",
          className: "w-full",
          onClick 
        }
      }
    ];

    // const toggleLoginState = () => {
    //     let newLogin = {};
    //     for (const attr of Object.keys(input)){
    //       newLogin[attr] = ""
    //     }

    //     setInput(() => newLogin);
    //     setLoginState(loginState === loginTypes.LOGIN ? loginTypes.REGISTER : loginTypes.LOGIN);
    // }

    
    let loginDirections = {
        title: "Sign in to your account",
        link : "create a free account",
        submit: "Sign in",
        href: "/register"
    };

    if (loginState === loginTypes.REGISTER) {
      loginDirections = {
          title : "Register for an account",
          link: "sign in to your account",
          submit: "Register",
          href: "/login"
      }
    };

    // const handleSubmitButton = () => {
    //     // Do the login / register stuff here
    //     if (loginState === loginTypes.LOGIN){
    //       login()
    //     } else {
    //       signup()
    //     }
    // }

    if(user.loggedIn){
      return <Navigate to={`/${user.username}`} replace />
    };

    
  return (
    <>
      <Notification show={showNotification} setShow={setShowNotification} {...notificationProps} />
      <div className="min-h-full flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
        <div className="max-w-md w-full space-y-8">
          <div>
            <img
              className="mx-auto h-12 w-auto"
              src="https://tailwindui.com/img/logos/workflow-mark-indigo-600.svg"
              alt="Workflow"
            />
            <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">{loginDirections.title}</h2>
            <p className="mt-2 text-center text-sm text-gray-600">
              Or{' '}
              <Link to={loginDirections.href}  className={`font-medium ${PRIMARY_TEXT_COLOR} ${HOVER_PRIMARY_TEXT_COLOR}`}>
                {loginDirections.link}
              </Link>
            </p>
          </div>
          <div className=''>
            {loginState === loginTypes.LOGIN && <FormRender {...{handleChange, getValue, setValue, buttonTemplate, template: template(loginState)}}/>}
            {loginState === loginTypes.REGISTER && <FormRender {...{handleChange, getValue, setValue, buttonTemplate, template: template(loginState)}}/>}
          </div>
        </div>
      </div>
    </>
  )
}

