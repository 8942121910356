import React from "react";
import { nonEmptyEntry } from "../Inputs";


export const nonEmptyError = {func: nonEmptyEntry, message: "Field must not be empty"};
export const lengthError = (count) => ({func: str=>str.length <= count, message: "Field is too long"});

export const FormRender = ({handleChange, getValue, setValue, buttonTemplate, template, additionalContent}) => {
    const [submitClicked, setSubmitClicked] = React.useState(false);
    const checkValid = useCheckValid(template, getValue)
    return (
        <div className="mt-6 space-y-3 divide-y divide-y-blue-gray-200">
            {
                template.map(({sectionTitle, sectionDescription, inputs}) => (
                    <Section key={sectionTitle} title={sectionTitle} description={sectionDescription}>
                        {
                            inputs.map((input, index) => {
                                if (input.name){
                                    // See if any errors exist
                                    const value = getValue(input.name)
                                    const errors = input.errors.filter(({func}) => !func(value));
                                    const errorMessages = errors.map(err=>err.message)
                                    const valid = errors.length === 0;
                                    const displayValid = submitClicked ? valid: null;
                                    const onBlur = input.updateOnBlur? () => setValue(input.name, input.updateOnBlur(value)): undefined;
                                return(
                                    <input.component 
                                        key={input.name} 
                                        valid={displayValid} 
                                        value={value} 
                                        invalidMessage={errorMessages}
                                        onChange={handleChange(input.name)}
                                        onBlur={onBlur}
                                        {...(input.props)} 
                                    />
                                )
                                }
                               
                                return <input.component key={index} {...(input.props)} />
                                
                                
                            })
                        }
                    </Section>
                ))
            }

            {
                additionalContent
            }
            
            {buttonTemplate && <div className="pt-8 flex justify-end gap-2">
                {
                    buttonTemplate.map((button, index)=> {
                        let onClick;
                        if (button.type === "SUBMIT") {
                            onClick = () => {
                                setSubmitClicked(true);
                                if (checkValid()) {
                                    button.props?.onClick()
                                }
                            }
                        } else {
                            onClick = button.props?.onClick;
                        }

                        
                        return <button.component key={index} {...(button.props)}  onClick={onClick}/>;
                    })
                }
                
            </div>}
            </div>
    )

}

const Section = ({children, title, description}) => (
    <div className="grid grid-cols-1 gap-y-2 sm:grid-cols-6 sm:gap-x-2 py-1">
        {title && <div className="sm:col-span-6 my-2">
            <h2 className="text-xl font-medium text-blue-gray-900">{title}</h2>
            <p className="mt-1 text-sm text-blue-gray-500">{description}</p>
        </div>}
        
        {children}
       
    </div>
)

export const useCheckValid = (template, getValue) => {
    return () => {
        for (const section of template){
            for (const input of section.inputs){
                if (input.name){
                   const value = getValue(input.name)
                    const errors = input.errors.filter(({func}) => !func(value));
                    if (errors.length > 0) return false; 
                }
                
            }
        }
        return true;
    }
}